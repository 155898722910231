import React from "react";
import ReactDOM from "react-dom";
import Button from "./Button";
import Card from "./Card";
import classes from "./ErrorModal.module.scss";
export const BackDrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onConfirm}></div>;
};
const ModalOverlay = (props) => {
  console.log("props in ModelOverly = ", props);
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h2>{props.title}</h2>
      </header>
      <div className={classes.content}>
        <p>{props.message}</p>
      </div>
      <footer className={classes.action}>
        <Button onClick={props.onConfirm} autoFocus={true}>
          Okay
        </Button>
      </footer>
    </Card>
  );
};
// const ErrorModal = (props) => {
//   return (
//     <React.Fragment>
//       {ReactDOM.createPortal(
//         <BackDrop onConfirm={props.onBackdropClicked} />,
//         document.getElementById("backdrop-root")
//       )}
//       {ReactDOM.createPortal(
//         <ModalOverlay
//           title={props.title}
//           message={props.message}
//           onConfirm={props.onConfirm}
//         />,
//         document.getElementById("overlay-root")
//       )}
//     </React.Fragment>
//   );
// };

export const Loader = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <BackDrop onConfirm={() => {}} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <div className={classes.loaderContainer}>
          <div className={classes.loader}></div>
        </div>,

        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

// export default ErrorModal;
