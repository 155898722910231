import { useState } from "react";
import classes from "./PasswordSetup.module.scss";

function PasswordSetup() {
  const phoneOrEmail = localStorage.getItem("phoneOrEmail");
  const [password, setPassword] = useState("welcome");
  const [otp, setOtp] = useState("123456");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <div>
        <label htmlFor="phoneOrEmail">Phone/Email:</label>
        <input
          className={classes.readOnlyInput}
          type="text"
          id="phoneOrEmail"
          name="phoneOrEmail"
          value={phoneOrEmail}
          readOnly={true}
        />
      </div>
      <div>
        <label htmlFor="otp">Otp:</label>
        <input
          type="number"
          id="otp"
          value={otp}
          name="otp"
          placeholder="6 digit number"
          onChange={handleOtpChange}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          name="password"
          placeholder="At least 8 characters"
          onChange={handlePasswordChange}
        />
      </div>
      <div>
        <button type="submit">Submit</button>
      </div>
    </>
  );
}

export default PasswordSetup;
