import { useState } from "react";
import AlertDialog from "../alertDialog/AlertDialog";
import styles from "./CircularImage.module.scss";
import { BackDrop } from "../ErrorModal";
function CircularImage({ profileImageUrl }) {
  const [showFullImage, setShowFullImage] = useState("");
  function hideFullImage() {
    setShowFullImage("");
  }
  function getFullImageView() {
    return (
      <img
        className={styles.fullImage}
        name="profileImage"
        alt="userImage"
        src={profileImageUrl}
        placeholder="Loading..."
      ></img>
    );
  }
  return (
    <div>
      {showFullImage && (
        <div className={styles.fullImageBackground}>
          <AlertDialog
            onOkClick={hideFullImage}
            onBackdropClicked={hideFullImage}
            title={"Zoomed In"}
            getContent={getFullImageView}
          />
        </div>
      )}
      <div
        onClick={(e) => {
          setShowFullImage("1");
        }}
      >
        <img
          className={styles.curcularImage}
          name="profileImage"
          alt="userImage"
          src={profileImageUrl}
          placeholder="Loading..."
        ></img>
      </div>
    </div>
  );
}
export default CircularImage;
