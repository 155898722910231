import { useNavigate } from "react-router-dom";
import styles from "./SearchGroomSummaryView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { SEARCH_GENDER, getRandomColorFromId } from "../../Constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { doNavigate } from "../../util/auth";

function SavedGroomLists(props) {
  // localStorage.setItem("lastPath", "searchGrooms");
  console.log("props : ", props);
  useEffect(() => {
    if (props.data === "logout") {
      doNavigate(navigate, "/welcome");
    }
  }, []);
  const allSavedGroomLists = useSelector(
    (state) => state.savedGroomLists.savedGroomLists
  );
  const groomLists = getGenderBasedGroomList();
  const navigate = useNavigate();
  function newSearchClicked(e) {
    doNavigate(navigate, "/searchGroomCriteria");
  }
  function getGenderBasedGroomList() {
    const gender = localStorage.getItem(SEARCH_GENDER);
    return allSavedGroomLists.filter((l) => l.gender === gender);
  }
  function getNoDataView() {
    return (
      <>
        <div className={commonStyles.topPanel}>
          <div style={{ color: "black", fontWeight: "bold" }}>
            No saved lists:
          </div>
          <button
            style={{ justifySelf: "left" }}
            className={commonStyles.topRightActionBtn}
            type="button"
            onClick={newSearchClicked}
          >
            New Search
          </button>
        </div>
        <h3 style={{ color: "black" }}>
          You can do new search and save shortlisted members in lists.
        </h3>
      </>
    );
  }

  function getSavedListsWithGradientView() {
    // background-image: linear-gradient(to right, #DECBA4, #3E5151)
    const gradients = [
      { start: "#a8c0ff", end: "#3f2b96", textColor: "white" },
      { start: "#d3cce3", end: "#59e4f0", textColor: "black" },
      { start: "#005aa7", end: "#1ffde4", textColor: "white" },
      { start: "#ffefba", end: "#aeefba", textColor: "black" },
      { start: "#9796f0", end: "#fbc7d4", textColor: "black" },
      { start: "#FFD194", end: "#D1913C", textColor: "black" },
      { start: "#b6fbff", end: "#83a4d4", textColor: "black" },
      { start: "#ff9966", end: "#ff5e62", textColor: "black" },
      { start: "#33001b", end: "#ff0084", textColor: "white" },
      { start: "#2c3e50", end: "#4ca1af", textColor: "white" },
      { start: "#ff512f", end: "#f09819", textColor: "black" },
      { start: "#ff6e7f", end: "#bfe9ff", textColor: "black" },
      { start: "#00b09b", end: "#96c93d", textColor: "black" },
      { start: "#00b4db", end: "#0083b0", textColor: "white" },
      { start: "#fc5c7d", end: "#6a82fb", textColor: "black" },
      { start: "#8e2de2", end: "#4a00e0", textColor: "white" },
      { start: "#8360c3", end: "#2ebf91", textColor: "white" },
      { start: "#DECBA4", end: "#3E5151", textColor: "black" },
      { start: "#d4fc79", end: "#96e6a1", textColor: "black" },
    ];
    function getBookmarkIconForList(list) {
      return (
        <i
          className="fa-solid fa-bookmark"
          style={{
            color: getRandomColorFromId(list.id),
            marginRight: "5px",
          }}
        ></i>
      );
    }
    function getTableForListDetails(list) {
      return (
        <table className={styles.listDetails}>
          <tbody>
            <tr>
              <th>Members</th>
              <th>Last Updated on</th>
            </tr>
            <tr>
              <td>{list.membersCount}</td>
              <td>{list.updatedOn}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    function onListSummaryClicked(list) {
      console.log("navigating to listMembers details -----------");
      doNavigate(navigate, "/listMembers/" + list.id);
    }
    function getGroomListSummaryWithGradient(list, index) {
      index = index % gradients.length;
      const gradientStyle = {
        backgroundImage: `linear-gradient(to right, ${gradients[index].start}, ${gradients[index].end} )`,
        color: `${gradients[index].textColor}`,
      };
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onListSummaryClicked(list);
          }}
          key={index}
          style={{
            display: "block",
            height: "85px",
            padding: "0 5px",
            borderRadius: "10px",
            margin: "0 5px 15px 5px",
            border: "1px solid lightgrey",
            ...gradientStyle,
          }}
        >
          {/* {getBookmarkIconForList(list)} */}
          <strong>
            <u>{list.name}</u>
          </strong>
          {getTableForListDetails(list)}
        </div>
      );
    }
    function getTopPanelWithSearchButton() {
      let title = "";
      const listCount = groomLists.length;
      if (listCount > 1) {
        title = listCount + " Saved Lists:";
      } else {
        title = listCount + " Saved List:";
      }
      return (
        <div className={commonStyles.topPanel}>
          <div style={{ color: "black", fontWeight: "bold" }}>{title}</div>
          <button
            className={commonStyles.topRightActionBtn}
            type="button"
            onClick={newSearchClicked}
          >
            New Search
          </button>
        </div>
      );
    }
    return (
      <>
        {getTopPanelWithSearchButton()}
        {groomLists.map((list, index) =>
          getGroomListSummaryWithGradient(list, index)
        )}
      </>
    );
  }

  if (props.data === "logout") {
    return <></>;
  } else {
    return (
      <>
        {groomLists.length < 1
          ? getNoDataView()
          : getSavedListsWithGradientView()}
      </>
    );
  }
}

export default SavedGroomLists;
