import { Suspense } from "react";
import { useRouteLoaderData, json, defer, Await } from "react-router-dom";
import { doLogout, getAuthToken } from "../../util/auth";
import { BASE_URL, CREATING_NEW_MEMBER } from "../../Constants";
import AllFamilyMembers from "../../components/allFamilyMembers/AllFamilyMebers";
import { fetchMembersSuccess } from "../../store/reducers/familyMembersSlice";
import { Loader } from "../../components/UI/ErrorModal";
import { authActions } from "../../store/reducers/authSlice";

function FamilyMembers() {
  const { routeLoaderData } = useRouteLoaderData("familyMembers");
  // todo we can give a nice Loader component in below fallback
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => <AllFamilyMembers data={routeLoaderData} />}
        </Await>
      </Suspense>
    </>
  );
}

export default FamilyMembers;

export async function loadFamilyMembers(dispatch, familyMembers) {
  return defer({ routeLoaderData: loadMembers(dispatch, familyMembers) });
}

async function clearCreatingFlagFromLocalStorage() {
  //it is synch operation already, but in the logs I was seeing that it was calling the backend api multiple times
  localStorage.removeItem(CREATING_NEW_MEMBER);
}
export async function loadMembers(dispatch, reduxFamilyMembers) {
  console.log("reduxFamilyMembers in loadMembers = ", reduxFamilyMembers);
  if (
    reduxFamilyMembers.members.length < 1 ||
    localStorage.getItem(CREATING_NEW_MEMBER) === "SAVED"
  ) {
    await clearCreatingFlagFromLocalStorage();
    //todo use below format of pullDataFromServer for all routes
    //For family member we are fetching members again and again from backend, because if the data is updated nd if the
    //image is updated, then we don't hve the updated url, so we re just pulling members from backend each time.
    //although we can avoid it during delete, but since members will not be too many, so we should be good
    return pullFamilyMembersFromServer(dispatch, reduxFamilyMembers);
  } else {
    return reduxFamilyMembers.members;
  }
}

async function pullFamilyMembersFromServer(dispatch, reduxFamilyMembers) {
  const token = getAuthToken();
  let url = BASE_URL + "/normalUser/familyMembers";
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response from fetch during loadMembers = ", response);

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json(
          { message: "Could not fetch details for selected event." },
          {
            status: 500,
          }
        );
      }
    } else {
      const resData = await response.json();
      console.log("got response ", resData);
      console.log(
        "resData length=",
        resData.length,
        ", reduxFamilyLength=",
        reduxFamilyMembers.members.length
      );
      //Only update the redux if we got more data otherwise from App
      //we will get update on state from redux and it will be infinite loop.
      //ideally we should compare the elements not just the length.
      //but that should be fine, because we will keep redux data up-to-date
      //when we will add/update or delete the members.
      if (resData.length !== reduxFamilyMembers.members.length) {
        dispatch(fetchMembersSuccess(resData));
      }
      return resData;
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during members fetch:", error);
    const status = error.status;
    console.error("status = ", status);
    return error;
  }
}
