import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getRouter from "./util/router";

function App() {
  useEffect(() => {
    console.log("App....do the one time task here.....");
  }, []);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const familyMembers = useSelector((state) => state.familyMembers);
  const friends = useSelector((state) => state.friends);
  const savedGroomLists = useSelector((state) => state.savedGroomLists);
  const browserRouter = createBrowserRouter(
    getRouter(dispatch, userProfile, familyMembers, friends, savedGroomLists)
  );
  return <RouterProvider router={browserRouter} />;
}

export default App;
