import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import styles from "./SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useEffect, useRef, useState } from "react";
import { fetchSearchedGrooms } from "./SearchedGroomLoader";
import PersonDetailsDialog from "../../components/UI/PersonDetailsDialog/PersonDetailsDialog";
import ViewForAvailableLists from "./ViewForAvailableLists";
import { useSelector } from "react-redux";
import { getRandomColorFromId } from "../../Constants";
import { useDispatch } from "react-redux";
import { textFor } from "../../util/languages";
import { getAge } from "../../util/viewUtils";
import { Loader } from "../../components/UI/ErrorModal";
import { useNavigate } from "react-router-dom";
import { doNavigate } from "../../util/auth";
function SearchedPeopleToMarryGridView({
  elements,
  pageNumber,
  pageSize,
  reqId,
  sortBy,
  sortOrder,
  totalElements,
  totalPages,
}) {
  const [isSendingReqest, setIsSendingRequest] = useState(false);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, isSendingReqest]);
  const dispatch = useDispatch();
  const viewRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const savedGroomLists = useSelector(
    (state) => state.savedGroomLists.savedGroomLists
  );
  const [data, setData] = useState({
    elements,
    pageNumber,
    pageSize,
    reqId,
    sortBy,
    sortOrder,
    totalElements,
    totalPages,
  });

  function fillUpParentListDetailsForAPerson(m2) {
    let m = { ...m2 };
    const parentList = savedGroomLists.find((list) =>
      list.memberIds.includes(m.id)
    );
    if (parentList) {
      m["parentListId"] = parentList.id;
      m["parentListName"] = parentList.name;
    } else {
      m["parentListId"] = "";
      m["parentListName"] = "";
    }
    return m;
  }
  let initialPersonStatus = data.elements.map((item, index) => {
    // since we are inside map so we can't modify the item so
    //in below 2 methods we will return new copy of modified items
    //from from this map method also we will return new copy of original item
    //which is updated.
    let item1 = fillUpParentListDetailsForAPerson(item);
    return getItemForPersonStatusList(item1);
  });
  //todo we should keep separate list for isChosen and isAddedInAList, currently both flags are in same state variable
  const [personStatus, setPersonStatus] = useState(initialPersonStatus);
  // console.log("In SearchedPeopleGrid elements=", elements);

  const [shouldShowMemberDetailDialog, setDialogShow] = useState("");
  const [shouldShowAvailableListDialog, setAvailableListsDialogShow] =
    useState("");
  const [personToViewDetail, setPersonToViewDetail] = useState(null);
  //---------------------------------------------------------------
  useEffect(() => {
    refreshPersonStatus(); //ignore the warning of adding this in dependency as we don't want to listen changes on this method
    console.log("doing REFRESHhhhhhhhhhhhhhhhh");
  }, [savedGroomLists]);

  useEffect(() => {
    // Restore scroll position after the state update
    if (viewRef.current) {
      viewRef.current.scrollTop = scrollPosition;
      console.log("setting scroll position to ", scrollPosition);
    }
  }, [data, scrollPosition]); // Trigger effect when data changes
  //---------------------------------------------------------------
  const currentElementsCount = data.elements.length;
  function checkIfIdIsInAList(id) {
    const objWithId = savedGroomLists.some((obj) => obj.memberIds.includes(id));
    if (objWithId) {
      return true;
    } else {
      return false;
    }
  }
  function choosePerson(id) {
    const personIndex = personStatus.findIndex((person) => person.id === id);

    if (personIndex !== -1) {
      // If person is present, toggle the isChosen value
      setPersonStatus((prevStatus) => {
        const updatedStatus = [...prevStatus];
        updatedStatus[personIndex] = {
          ...updatedStatus[personIndex],
          isChosen: !updatedStatus[personIndex].isChosen,
        };
        return updatedStatus;
      });
    } else {
      console.error("how is it possible, the person is not found for id: ", id);
      //setPersonStatus((prevStatus) => [...prevStatus, { id, isChosen: true }]);
    }
  }
  function viewPerson(member) {
    const m = { ...member };
    console.log("Viewing person = ", m);
    setPersonToViewDetail(m);
    setDialogShow("1");
  }
  function hidePersonDetailDialog() {
    setPersonToViewDetail(null);
    setDialogShow("");
  }
  function isPersonChosen(id) {
    return personStatus.some((person) => person.id === id && person.isChosen);
  }
  function refreshPersonStatus() {
    const updatedPersonsStatus = [...personStatus].map((item) => {
      return getItemForPersonStatusList(item);
    });
    setPersonStatus(updatedPersonsStatus);
  }
  function isIdAddedInAList(id) {
    return personStatus.some(
      (person) => person.id === id && person.addedInList
    );
  }
  function isAtLeastOnePersonSelected() {
    for (let i = 0; i < personStatus.length; i++) {
      if (personStatus[i].isChosen === true) {
        return true;
      }
    }
    return false;
  }
  function addToListClicked(e) {
    if (isAtLeastOnePersonSelected()) {
      setAvailableListsDialogShow("1");
    } else {
      window.alert(textFor("selectAtLeastOnePerson"));
    }
  }
  function getItemForPersonStatusList(item2) {
    let item = { ...item2 };
    const inListFlag = checkIfIdIsInAList(item.id);
    item.addedInList = inListFlag;
    if (inListFlag) {
      item.isChosen = false; //if an item is added to a list, that should not be shown as chosen or selected
    }
    return item;
  }
  async function loadMoreClicked(e) {
    console.log("SAVING scroll position on LOADMORE to ", scrollPosition);
    setScrollPosition(viewRef.current.scrollTop);
    setIsSendingRequest(true);
    const newPage = await fetchSearchedGrooms(
      dispatch,
      data.pageNumber + 1,
      data.reqId,
      data.sortBy,
      data.sortOrder
    );
    let dataElements = [...data.elements];
    dataElements = dataElements.concat(newPage.elements);
    const newPageElements = [...newPage.elements];
    setPersonStatus((prevStatus) => {
      const statusForNewElements = newPageElements.map((item) => {
        fillUpParentListDetailsForAPerson(item);
        return getItemForPersonStatusList(item);
      });
      const updatedStatus = [...prevStatus].concat(statusForNewElements);
      return updatedStatus;
    });

    newPage.elements = dataElements;
    setData(newPage);
    setIsSendingRequest(false);
  }
  function getCheckboxes(m) {
    //space before fa-regular below is mandatory
    return (
      <div onClick={() => choosePerson(m.id)} className={styles.edit}>
        {isPersonChosen(m.id) ? (
          <i
            className={`${styles.circularCheckbox} ' fa-regular fa-circle-check fa-1x' `}
          ></i>
        ) : (
          <i
            className={`${styles.circularCheckbox} ' fa-regular fa-circle fa-1x' `}
          ></i>
        )}
      </div>
    );
  }

  function getBookmarkIcon(m) {
    const color = getRandomColorFromId(m.parentListId);
    return (
      <div className={styles.bookmarked} style={{ color: color }}>
        <i className="fa-solid fa-bookmark fa-1x"></i>
      </div>
    );
  }
  function getRightSideButtonPanelOnMemberView(m) {
    return (
      <>
        {isIdAddedInAList(m.id) ? getBookmarkIcon(m) : getCheckboxes(m)}
        {/* <div onClick={() => viewPerson(m)} className={styles.view}>
          <i className="fa-regular fa-eye"></i>
        </div> */}
      </>
    );
  }
  function getFullNameField(m) {
    return (
      <div className={commonStyles.fullNameWrapper}>
        <div className={commonStyles.fullName}>{m.fullName}</div>
      </div>
    );
  }
  function getGotraNormalCase(m) {
    if (m.gotraType) {
      const str = m.gotraType + "";
      return str.charAt(0) + str.slice(1).toLowerCase();
    } else {
      return "X";
    }
  }
  function getGotraAndAge(m) {
    return (
      <div>
        {/* <p>{getGotraNormalCase(m) + ", " + getAge(m)}</p> */}
        <p>{(m.gotraType || "X") + ", " + getAge(m)}</p>
      </div>
    );
  }
  function getImageAndTextForMemberView(m) {
    let profileImageUrl = m.profileImageUrl;
    if (!profileImageUrl) {
      profileImageUrl = userPlaceholderImageUrl;
    }
    return (
      <>
        <div
          onClick={(e) => {
            viewPerson(m);
            //don't call a method on onClick, provide a function which will do the calling or just provide name of the function
          }}
          className={styles.imgDiv}
        >
          <img src={profileImageUrl} alt="profilePic" />
        </div>
        {getFullNameField(m)}
        {getGotraAndAge(m)}
      </>
    );
  }
  function getMemberView(m) {
    return (
      <div key={m.id} className={styles.grid__item}>
        {getRightSideButtonPanelOnMemberView(m)}
        {getImageAndTextForMemberView(m)}
      </div>
    );
  }
  function getMembersInGridView() {
    return (
      <div ref={viewRef} className={styles.grid}>
        {data.elements.map((item, index) => (
          <div key={item.id} className={styles.grid__item}>
            {getMemberView(item)}
          </div>
        ))}
      </div>
    );
  }
  function getLowerPanelButtonsAndLoadedStatus() {
    return (
      <div className={styles.lowerButtonPanel}>
        <button
          className={
            currentElementsCount === totalElements
              ? commonStyles.lightgrayButton
              : commonStyles.grayButton
          }
          type="button"
          onClick={loadMoreClicked}
          disabled={
            totalElements == "0" || currentElementsCount === totalElements
          }
        >
          Load More
        </button>
        <button
          className={
            totalElements === 0
              ? commonStyles.lightgrayButton
              : commonStyles.yellowButton
          }
          type="button"
          onClick={addToListClicked}
          disabled={totalElements == "0"}
        >
          Add to List
        </button>
        <div style={{ color: "black" }}>
          Showing{" "}
          <div style={{ display: "inline", fontWeight: "bold" }}>
            {currentElementsCount + "/" + totalElements}
          </div>
        </div>
      </div>
    );
  }
  function getViewToShowPersonsGrid() {
    return (
      <div className={styles.wrapper} id="persons-view">
        <div className={commonStyles.topPanel} style={{ color: "black" }}>
          Search results:
        </div>
        {getMembersInGridView()}
        {getLowerPanelButtonsAndLoadedStatus()}
      </div>
    );
  }
  function ifNeedsShowMemberDetailDialog() {
    if (shouldShowMemberDetailDialog) {
      return (
        <PersonDetailsDialog
          onClose={hidePersonDetailDialog}
          personDetails={personToViewDetail}
          getViewForTopRightArea={() => {}}
        />
      );
    } else {
      return "";
    }
  }
  function hideAvailableListDialog() {
    setAvailableListsDialogShow("");
  }
  function ifNeedsShowAvailableGroomListsDialog() {
    if (shouldShowAvailableListDialog) {
      return (
        <ViewForAvailableLists
          hideAvailableListDialog={hideAvailableListDialog}
          personStatus={personStatus}
        />
      );
    } else {
      return "";
    }
  }
  return (
    <>
      {isSendingReqest && <Loader />}
      {ifNeedsShowAvailableGroomListsDialog()}
      {ifNeedsShowMemberDetailDialog()}
      {getViewToShowPersonsGrid()}
    </>
  );
}

export default SearchedPeopleToMarryGridView;
