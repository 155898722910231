import { LANGUAGE_KEY } from "../Constants";

function getEngText(key) {
  switch (key) {
    case "loginButton":
      return "Login";
    case "registerButton":
      return "Register";
    case "welcomeTitleLine1":
      return "Welcome to this site";
    case "welcomeTitleLine2":
      return "For Yadav Ahir Community";
    case "selectAtLeastOnePerson":
      return "Please select, at least one person, by touching yellow cirle. The selected persons will be saved in the list.";
    case "nameIsMandatoryField":
      return "Name is mandatory.";
    case "noFieldIsUpdated":
      return "No field is changed.";
    case "cantSaveLoginAgain":
      return "Your login has expired, so details can't be saved. Please login again and retry...";
    default:
      console.log("ERROR: REQUEST FOR TEXT FOR UNKNOW KEY: ", key);
      return "UNKNOWN-KEY";
  }
}

function getHindiText(key) {
  switch (key) {
    case "loginButton":
      return "लॉगिन करें";
    case "registerButton":
      return "रजिस्टर करें";
    case "welcomeTitleLine1":
      return "यादव अहीर समाज की इस वेब साईट पर";
    case "welcomeTitleLine2":
      return "आपका हार्दिक स्वागत है";
    case "selectAtLeastOnePerson":
      return "कृपया, पीले-गोल निशान पर टच करके, कुछ लोगों को सेलेक्ट करें, उन्हें ही लिस्ट में सेव किया जाएगा.";
    case "nameIsMandatoryField":
      return "नाम टाइप करना ज़रूरी है.";
    case "noFieldIsUpdated":
      return "कोई भी जानकारी अपडेट नहीं की गयी है.";
    case "cantSaveLoginAgain":
      return "आपका लॉगिन निरस्त हो चुका है, इसलिए जानकारी सेव नहीं हो पायी, कृपया फिर से लॉगिन करने के बाद ट्राय करें.";
    default:
      console.log("ERROR: REQUEST FOR TEXT FOR UNKNOW KEY: ", key);
      return "अज्ञात आई.डी.";
  }
}

export function textFor(key) {
  let textFun = getEngText;
  const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
  if (currentLanguage) {
    switch (currentLanguage) {
      case "HINDI":
        textFun = getHindiText;
        break;
      case "ENGLISH":
      default:
        textFun = getEngText;
    }
  }
  return textFun(key);
}
