// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    token: null,
    isLoading: false,
    // Other relevant authentication state
  },
  reducers: {
    loginRequest: (state, action) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      // Handle other user data if needed
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      // Reset other authentication state as needed on login failure
    },
    logoutRequest: (state, action) => {
      state.isLoading = true;
    },
    logoutSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      // Reset other authentication state as needed on logout
    },
    // Other reducers...
  },
});

// export const {
//   loginRequest,
//   loginSuccess,
//   loginFailure,
//   logoutRequest,
//   logoutSuccess,
// } = authSlice.actions;

export const authActions = authSlice.actions; //actions similar to function names will be created like above object.
export default authSlice.reducer;
