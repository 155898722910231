import { useState } from "react";
import { Form, json, Link, redirect, useNavigation } from "react-router-dom";
import { isAnEmail, isAPhoneNumber } from "../../util/emailPhoneChecker";
import { BASE_URL } from "../../Constants";

function Register() {
  const navigation = useNavigation();
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const isSubmitting = navigation.state === "submitting";
  const handlePhoneOrEmailChange = (e) => {
    setPhoneOrEmail(e.target.value);
  };

  return (
    <div>
      <h2>Register</h2>
      <Form method="post">
        <div>
          <label htmlFor="phoneOrEmail">Phone/Email:</label>
          <input
            type="text"
            id="phoneOrEmail"
            name="phoneOrEmail"
            value={phoneOrEmail}
            onChange={handlePhoneOrEmailChange}
          />
        </div>
        <div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Register"}
          </button>
        </div>
      </Form>
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
  );
}
export default Register;

export async function action({ request, params }) {
  console.log("register action is called.. request = ", request);
  const data = await request.formData();
  let registrationData = {};
  const userInput = data.get("phoneOrEmail").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    registrationData = {
      phone: userInput,
    };
    url += "registerWithPhone";
  } else if (isAnEmail(userInput)) {
    registrationData = {
      email: userInput,
    };
    url += "registerWithEmail";
  } else {
    return null;
    // throw json(
    //   { message: "Please input a valid phone or email" },
    //   { status: 500 }
    // );
  }

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });

  if (!response.ok) {
    throw json({ message: "Could not register." }, { status: 500 });
  }
  const responseJson = await response.json();
  console.log("register response : ", responseJson);
  localStorage.setItem("phoneOrEmail", userInput);
  localStorage.setItem("userId", responseJson.id);

  return redirect("setRegistrationPassword");
}
