import { Suspense, useEffect } from "react";
import {
  useRouteLoaderData,
  json,
  defer,
  Await,
  useNavigate,
} from "react-router-dom";

import {
  BASE_URL,
  SEARCH_GENDER,
  SEARCH_GROOM_CRITERIA,
} from "../../Constants";
import { doLogout, doNavigate, getAuthToken } from "../../util/auth";
import SearchedPeopleToMarryGridView from "./SearchedPeopleToMarryGridView";
import { getFilterStr } from "../../util/filterUtil";
import { fetchedResults } from "../../store/reducers/searchedGroomSlice";
import { Loader } from "../../components/UI/ErrorModal";
import { authActions } from "../../store/reducers/authSlice";
import { useSelector } from "react-redux";
function SearchedGrooms(props) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated]);
  const { routeLoaderData } = useRouteLoaderData("searchedGrooms");
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => (
            <SearchedPeopleToMarryGridView {...routeLoaderData} />
          )}
        </Await>
      </Suspense>
    </>
  );
}
export default SearchedGrooms;
export async function loadSearchedGrooms(dispatch) {
  return defer({ routeLoaderData: fetchSearchedGrooms(dispatch) });
}

function getUrlBasedOnGender(pageNumber, reqId, sortBy, sortOrder) {
  //here, pick the filters value from localStorage using
  //searchGroomCriteria.
  const gender = localStorage.getItem(SEARCH_GENDER);
  const searchCriteria = JSON.parse(
    localStorage.getItem(SEARCH_GROOM_CRITERIA)
  );

  const filterStr = getFilterStr(searchCriteria);
  console.log("filterStr=", filterStr);
  return (
    BASE_URL +
    "/grooms/search?reqId=" +
    reqId +
    "&pageSize=12&pageNumber=" +
    pageNumber +
    "&gender=" +
    gender +
    filterStr
  );
}
export async function fetchSearchedGrooms(
  dispatch,
  pageNumber = 0,
  reqId = 0,
  sortBy = "id",
  sortOrder = "ASC"
) {
  if (reqId == 0) {
    const reduxSearchedGrooms = JSON.parse(
      localStorage.getItem("reduxState_searchedGrooms")
    );
    if (Object.keys(reduxSearchedGrooms.searchedResults).includes("elements")) {
      return { ...reduxSearchedGrooms.searchedResults };
    } //else it will return results by calling api.
  }
  //else return result by calling api
  let results = await callApiToFetchSearchedGrooms(
    pageNumber,
    reqId,
    sortBy,
    sortOrder,
    dispatch
  );
  if (results !== "logout") {
    dispatch(fetchedResults(results));
    return results;
  } else {
    return { elements: [] };
  }
}
export async function callApiToFetchSearchedGrooms(
  pageNumber,
  reqId,
  sortBy,
  sortOrder,
  dispatch
) {
  const token = getAuthToken();
  const url = getUrlBasedOnGender(pageNumber, reqId, sortBy, sortOrder);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response from fetch during fetchSearchedPeople = ", response);

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        window.alert("Error: " + errorResponse.errorMessage);
        return {
          elements: [],
          pageNumber: 0,
          pageSize: 0,
          reqId: 0,
          sortBy: "id",
          sortOrder: "ASC",
          totalElements: 0,
          totalPages: 0,
        };
      } else {
        throw json({
          message: "Could not fetch details for fetchSearchedPeople.",
          status: 500,
        });
      }
    } else {
      let searchedPeople = await response.json();
      // searchedPeople.elements.map((item) => {
      //   item["parentListId"] = "";
      //   item["parentListName"] = "";
      // });
      console.log("search groom response ", searchedPeople);
      return searchedPeople;
      // return getGroomData();
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during fetchSearchedPeople:", error);
    const status = error.status;
    console.error("status = ", status);
    // return error;
    return getGroomData();
  } //catch
}

function getGroomData() {
  return [];
  //return dummyGetPeople();
}
