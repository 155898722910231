import React from "react";
import styles from "./Dropdown.module.scss";

const Dropdown = ({
  name,
  label,
  value,
  onChange,
  options,
  disabled,
  message,
  multiple = false,
}) => (
  <label className={styles.label}>
    {label}
    <select
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={styles.inputField}
      multiple={multiple}
    >
      <option key="nothing" value="" disabled>
        {message || `"Select "${label}`}
      </option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  </label>
);

export default Dropdown;
