import { useSelector } from "react-redux";
import MemberDetails from "../memberDetails/MemberDetails";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doNavigate } from "../../util/auth";

function EditMember() {
  const navigate = useNavigate();
  const familyMembers = useSelector((state) => state.familyMembers);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, []);
  const targetMemberId = localStorage.getItem("memberIdToEdit");
  console.log(
    "in EditMember: targetMemberId=",
    targetMemberId,
    ", familyMembers.members=",
    familyMembers.members
  );
  //Here use == only, not === because the types of values differ
  const memberDataToEdit = familyMembers.members.find(
    (item) => "" + item.id === targetMemberId
  );
  const memberDetails = { ...memberDataToEdit, isEditing: true }; //enable the edit in the view member section
  console.log("in EditMember: memberDetails=", memberDetails);
  return (
    <>
      {memberDataToEdit && (
        <MemberDetails registrationDetails={memberDetails} />
      )}
    </>
  );
}
export default EditMember;
