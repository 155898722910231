// export const BASE_URL = "http://192.168.0.117:8081/v1"; //groundFloor
// export const BASE_URL = "http://192.168.117.70:8081/v1"; //darkweb
//export const BASE_URL = "http://192.168.29.56:8081/v1"; //rock3
export const BASE_URL = "https://qa741.yadavahir.com/ss-service/v1";

export const SEARCH_GROOM_CRITERIA = "searchGroomCriteria";
export const SEARCH_PEOPLE_CRITERIA = "searchPeopleCriteria";
export const NAME = "Name";
export const PHONE = "Phone";
export const GENDER = "Gender";
export const SEARCH_GENDER = "searchGender";
export const MALE_LIST_COUNT = "maleListCount";
export const FEMALE_LIST_COUNT = "femaleListCount";
export const OPTIONAL_FILTERS = "optionalFilters";
export const DISTANCE_RANGE = "Distance range";
export const MIN_EDU = "Min Education";
export const AGE = "Age";
export const GOTRA = "Gotra";
export const HEIGHT = "Height";
export const WEIGHT = "Weight";
export const MARITAL_STATUS = "Marital status";
export const KUNDLI_DOSHA = "Kundli dosha";
export const PURE_VEG = "pureVeg";
export const SPECIAL_ABILITY = "Special ability";
export const MINIMUM_YEARLY_INCOME = "Mininum Yearly income";
export const LANGUAGE_KEY = "userSelectedLanguage";
//--------------------------------------------
export const MIN_AGE = "minAge";
export const MAX_AGE = "maxAge";
export const FROM_HEIGHT_FEET = "fromHeightFeet";
export const FROM_HEIGHT_INCHES = "fromHeightInches";
export const TO_HEIGHT_FEET = "toHeightFeet";
export const TO_HEIGHT_INCHES = "toHeightInches";
export const MIN_WEIGHT = "minWeight";
export const MAX_WEIGHT = "maxWeight";
export const CREATING_NEW_MEMBER = "creatingNewMember";
export const LAST_PAGE = "LAST_PAGE";
//--------------------------------------------
export const listColors = [
  "Black",
  "BlueViolet",
  "Chartreuse",
  "Chocolate",
  "Crimson",
  "DeepPink",
  "DodgerBlue",
  "MediumOrchid",
  "Navy",
  "Olive",
  "Orange",
  "PowderBlue",
  "Purple",
  "Red",
  "SeaGreen",
  "Sienna",
  "SlateGray",
  "Teal",
  "Wheat",
  "Yellow",
];
export function getRandomColorFromId(parentListId) {
  const max = listColors.length;
  const index = parentListId % max;
  return listColors[index];
}
//--------------------------------------------
export const MEMBER_DETAILS = "memberDetails";
export const ACTIVE_TAB_IN_FRIENDS = "activeTabInFR";
export const CONNECTED_FRIENDS = "CONNECTED_FRIENDS";
export const BLOCKED_FRIENDS = "BLOCKED_FRIENDS";
export const PENDING_REQUESTS = "PENDING_REQUESTS";
export const WAITING_REQUESTS = "WAITING_REQUESTS";
export const REJECTED_REQUESTS = "REJECTED_REQUESTS";
export const I_REQUESTED = "I_REQUESTED";
export const THEY_REQUESTED = "THEY_REQUESTED";
//--------------------------------------------
export const CONNECTED = "CONNECTED";
export const REJECTED = "REJECTED";
export const BLOCKED = "BLOCKED";
//--------------------------------------------
export const SOURCE_REQUESTED = "SOURCE_REQUESTED";
export const TARGET_ACCEPTED = "TARGET_ACCEPTED";
export const TARGET_REJECTED = "TARGET_REJECTED";
export const TARGET_BLOCKED = "TARGET_BLOCKED";
//--------------------------------------------
export const ACCEPT = "ACCEPT";
export const REJECT = "REJECT";
export const RESEND = "RESEND";
export const DELETE = "DELETE";
export const BLOCK = "BLOCK";
export const UNBLOCK = "UNBLOCK";
//--------------------------------------------
export const FILTER_MIN_HEIGHT_FEET = "MIN_HEIGHT_FEET";
export const FILTER_MAX_HEIGHT_FEET = "MAX_HEIGHT_FEET";
export const FILTER_MIN_HEIGHT_INCHES = "MIN_HEIGHT_INCHES";
export const FILTER_MAX_HEIGHT_INCHES = "MAX_HEIGHT_INCHES";
export const FILTER_MAX_BIRTH_YEAR = "MAX_BIRTH_YEAR";
export const FILTER_MIN_BIRTH_YEAR = "MIN_BIRTH_YEAR";
export const FILTER_MIN_EDUCATION_LEVEL = "MIN_EDUCATION_LEVEL";
export const FILTER_MIN_WEIGHT = "MIN_WEIGHT";
export const FILTER_MAX_WEIGHT = "MAX_WEIGHT";
export const FILTER_MIN_YEARLY_INCOME = "MIN_YEARLY_INCOME";
export const FILTER_MARITAL_STATUS_IN = "MARITAL_STATUS_IN";
export const FILTER_FAMILY_RELATION_TYPE = "FAMILY_RELATION_TYPE";
export const FILTER_GOTRA_IN = "GOTRA_IN";
export const FILTER_GENDER = "gender";
export const FILTER_NAME_LIKE = "NAME_LIKE";
export const FILTER_IS_PURE_VEG = "IS_PURE_VEG";
export const FILTER_IS_MANGLIK_DOSH = "IS_MANGLIK_DOSH";
export const FILTER_IS_CHANDRA_DOSH = "IS_CHANDRA_DOSH";
export const FILTER_IS_GURU_CHANDAL_DOSH = "IS_GURU_CHANDAL_DOSH";
export const FILTER_IS_KAAL_SARPA_DOSH = "IS_KAAL_SARPA_DOSH";
export const FILTER_IS_KEMADRUMA_DOSH = "IS_KEMADRUMA_DOSH";
export const FILTER_IS_NADI_DOSH = "IS_NADI_DOSH";
export const FILTER_IS_PITRA_DOSH = "IS_PITRA_DOSH";
export const FILTER_IS_RAHU_DOSH = "IS_RAHU_DOSH";
export const FILTER_IS_VYATIPATA_DOSH = "IS_VYATIPATA_DOSH";
export const FILTER_IS_SPECIALLY_ABLED = "IS_SPECIALLY_ABLED";
export const FILTER_DISTANCE_RANGE = "DISTANCE_RANGE";
//--------------------------------------------

//todo if we are sending a pageNumber to backend for which we don't get any data, then don't update that
//pageNumber in redux otherwise next time we will send next pageNumber, e.g.
// if we send pageNumber=10 whereas there were only 2 pages, then it will not end any data,
//and currently we increase pageNumber to 11 (to send next pageNumber next time)
