import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { doNavigate } from "../../util/auth";
import {
  Form,
  json,
  redirect,
  Link,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { isAPhoneNumber, isAnEmail } from "../../util/emailPhoneChecker";
import { BASE_URL } from "../../Constants";

function ForgotPassword() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    //TODO THIS SHOULD NOT BE REUIRED AS WE ARE ALREADY USING ROUTER LOADER
    if (isAuthenticated) {
      // Navigate to "/dashboard" route
      // Example with useNavigate from React Router
      doNavigate(navigate, "/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("a1@b.com");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    localStorage.setItem("loginUsername", e.target.value);
  };

  return (
    <>
      Enter your email or phone to reset password
      <br />
      <Form method="post">
        <div>
          <label htmlFor="username">phoneOrEmail:</label>
          <input
            type="text"
            id="username"
            name="phoneOrEmail"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>

        <div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
      <Link to="/login">Login</Link>
      <br />
      <Link to="/register">Register</Link>
      <br />
      More info...
    </>
  );
}

export default ForgotPassword;

export async function action({ request, params }) {
  console.log("forgotPassword action is called.. request = ", request);
  const data = await request.formData();
  let registrationData = { captchaToken: "dummy" };
  const userInput = data.get("phoneOrEmail").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    registrationData.phone = userInput;
    url += "generatePhoneOtpToResetPassword";
  } else if (isAnEmail(userInput)) {
    registrationData.email = userInput;
    url += "generateEmailOtpToResetPassword";
  } else {
    return null;
    // throw json(
    //   { message: "Please input a valid phone or email" },
    //   { status: 500 }
    // );
  }

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });

  if (!response.ok) {
    throw json({ message: "Could not register." }, { status: 500 });
  }
  console.log("response  : ", response);
  localStorage.setItem("phoneOrEmail", userInput);

  return redirect("setNewPassword");
}
