import React from "react";
import styles from "./ImageButton.module.scss";

function ImageButton({ imageUrl, onClick, onImageLoad = (e) => {} }) {
  return (
    <button className={styles.button} onClick={onClick}>
      <img
        onLoad={(e) => {
          onImageLoad("1");
        }}
        src={imageUrl}
        alt="Button"
        className={styles.image}
      />
    </button>
  );
}

export default ImageButton;
