import styles from "./AddressFieldsWrapper.module.scss";
import commonStyles from "../../../commonStyles.module.scss";
import AddressFields from "./addressFields/AddressFields";
import { doLogout, doNavigate, getAuthToken } from "../../../util/auth";
import { Loader } from "../../UI/ErrorModal";
import { BASE_URL } from "../../../Constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/reducers/authSlice";
import { useSelector } from "react-redux";
const AddressFieldsWrapper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, navigate]);
  const initialUsersDetails = {
    usersStateId: props.registrationDetails.addressStateName,
    usersCityId: props.registrationDetails.addressCityName,
    usersDetailedAddress: props.registrationDetails.detailedAddress,
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userDetails, setUserDetails] = useState(initialUsersDetails);

  const updateAddressStateId = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersStateId: value };
    });
  };
  const updateAddressCityId = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersCityId: value };
    });
  };

  const updateDetailedAddress = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersDetailedAddress: value };
    });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleOkClick = async () => {
    setIsEditing(false);
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateRegistrationDetails";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          addressStateName: userDetails.usersStateId,
          addressCityName: userDetails.usersCityId,
          language: userDetails.usersLanguageName,
          detailedAddress: userDetails.usersDetailedAddress,
        }),
      });

      console.log("response is = ", response);
      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          alert("Invalid response: " + response);
        }
      }
    } catch (error) {
      console.error("Error during POST request:", error);
      doNavigate(navigate, "/logout");
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    setUserDetails((userDetails) => initialUsersDetails);
    setIsEditing(false);
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <div className={styles.userDetailsContainer}>
        <div className={commonStyles.actions}>
          {/* <h4>Address:</h4> */}
          {isEditing ? (
            <>
              <button
                className={commonStyles.greenButton}
                onClick={handleOkClick}
              >
                Ok
              </button>
              <button
                className={commonStyles.magentaButton}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              className={commonStyles.brownButton}
              onClick={handleEditClick}
            >
              Edit
            </button>
          )}
        </div>
        <AddressFields
          isEditing={isEditing}
          usersStateId={userDetails.usersStateId}
          usersCityId={userDetails.usersCityId}
          usersDetailedAddress={userDetails.usersDetailedAddress}
          updateAddressStateId={updateAddressStateId}
          updateAddressCityId={updateAddressCityId}
          updateDetailedAddress={updateDetailedAddress}
        />
      </div>
    </>
  );
};
export default AddressFieldsWrapper;
