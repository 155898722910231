import { useNavigate } from "react-router-dom";
import styles from "./FriendAndRequests.module.scss";
import { useEffect, useRef, useState } from "react";
import ConnectedFriends from "./ConnectedFriends";
import PendingRequests from "./PendingRequests";
import BlockedPersons from "./BlockedPersons";
import RejectedRequests from "./RejectedRequests";
import WaitingRequests from "./WaitingRequests";
import { scrollTabToMakeFullyVisible } from "../../util/viewUtils";
import {
  ACTIVE_TAB_IN_FRIENDS,
  BLOCKED_FRIENDS,
  REJECTED_REQUESTS,
  CONNECTED_FRIENDS,
  PENDING_REQUESTS,
  WAITING_REQUESTS,
} from "../../Constants";
import { fetchFriendsOfActiveTab } from "../../pages/friends/FriendsLoader";
import { useDispatch } from "react-redux";
import { Loader } from "../UI/ErrorModal";
import { useSelector } from "react-redux";
import { doNavigate } from "../../util/auth";

export function getActiveTab() {
  const activeTab = localStorage.getItem(ACTIVE_TAB_IN_FRIENDS);
  if (!activeTab) {
    return tabIds.connectedFriends; //default
  }
  return activeTab;
}
const tabIds = {
  connectedFriends: CONNECTED_FRIENDS,
  pendingRequests: PENDING_REQUESTS,
  waitingRequests: WAITING_REQUESTS,
  rejectedRequests: REJECTED_REQUESTS,
  blockedFriends: BLOCKED_FRIENDS,
};
function FriendAndRequests(props) {
  // console.log("In FriendAndRequests props: ", props);
  // console.log("props.data = ", props.data);
  const auth = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(null);
  const [isLoading, setIsLoading] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabLinkRef = useRef(null);
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    } else {
      const activeTabName = getActiveTab();
      setActiveTab(activeTabName);
      if (activeTabName === tabIds.blockedFriends) {
        tabLinkRef.current.scrollLeft = tabLinkRef.current.scrollWidth;
      } else if (activeTabName === tabIds.connectedFriends) {
        tabLinkRef.current.scrollLeft = 0;
      }
    }
  }, [navigate]);
  //----------------------------------------------------------------------------
  async function activateTabAndPullDataIfNeeds(clickedTabId) {
    console.log("clickedTabId = ", clickedTabId);
    //api will use it so set it first before api call
    localStorage.setItem(ACTIVE_TAB_IN_FRIENDS, clickedTabId);
    setIsLoading("1");
    await fetchFriendsOfActiveTab(dispatch);
    setActiveTab(clickedTabId);

    setIsLoading("");
  }
  const tabClicked = async (event) => {
    /* Since we have div and <i> as children in button, in the event.target it sometimes give <i> or <div> element
    when we closely click on them, but it should give the wrapper button id, so we have to use closest() method
    to find the closest button component from the clicked item.
     */
    scrollTabToMakeFullyVisible(event);
    const clickedTabId = event.target.closest("button").id;
    // console.log("clicked event: ", event);
    // console.log("clicked event.target: ", event.target);
    await activateTabAndPullDataIfNeeds(clickedTabId);
  };
  function getConnectedTabButton() {
    return (
      <button
        id={tabIds.connectedFriends}
        className={`${styles.tablinks} ${
          activeTab === tabIds.connectedFriends && styles.activeTab
        }`}
        onClick={tabClicked}
      >
        <i className="fa-solid fa-people-group"></i>
        <div>Connected</div>
      </button>
    );
  }
  function getBlockedTabButton() {
    return (
      <button
        id={tabIds.blockedFriends}
        className={`${styles.tablinks} ${
          activeTab === tabIds.blockedFriends && styles.activeTab
        }`}
        onClick={tabClicked}
      >
        <i className="fa-solid fa-users-slash"></i>
        {/* <i className="fa-solid fa-ban"></i> */}
        <div>Blocked</div>
      </button>
    );
  }
  function getPendingTabButton() {
    return (
      <button
        id={tabIds.pendingRequests}
        className={`${styles.tablinks} ${
          activeTab === tabIds.pendingRequests && styles.activeTab
        }`}
        onClick={tabClicked}
      >
        {/* <i className="fa-solid fa-arrow-right-arrow-left"></i> */}
        <i className="fa-solid fa-clock-rotate-left"></i>
        <div>Pending</div>
      </button>
    );
  }
  function getWaitingTabButton() {
    return (
      <button
        id={tabIds.waitingRequests}
        className={`${styles.tablinks} ${
          activeTab === tabIds.waitingRequests && styles.activeTab
        }`}
        onClick={tabClicked}
      >
        <i className="fa-regular fa-hourglass"></i>
        <div>Waiting</div>
      </button>
    );
  }
  function getRejectedTabButton() {
    return (
      <button
        id={tabIds.rejectedRequests}
        className={`${styles.tablinks} ${
          activeTab === tabIds.rejectedRequests && styles.activeTab
        }`}
        onClick={tabClicked}
      >
        <i className="fa fa-ban"></i>
        <div>Rejected</div>
      </button>
    );
  }

  function getTopTabButtonsRow() {
    return (
      <div ref={tabLinkRef} className={styles.tab}>
        {getConnectedTabButton()}
        {getPendingTabButton()}
        {getWaitingTabButton()}
        {getRejectedTabButton()}
        {getBlockedTabButton()}
      </div>
    );
  }
  function getConnectedFriendsView() {
    return (
      <div
        className={styles.tabcontent}
        style={{
          display: activeTab === tabIds.connectedFriends ? "flex" : "none",
        }}
      >
        {activeTab === tabIds.connectedFriends ? <ConnectedFriends /> : ""}
      </div>
    );
  }
  function getBlockedFriendsView() {
    return (
      <div
        className={styles.tabcontent}
        style={{
          display: activeTab === tabIds.blockedFriends ? "flex" : "none",
        }}
      >
        {activeTab === tabIds.blockedFriends ? <BlockedPersons /> : ""}
      </div>
    );
  }
  function getPendingRequestsView() {
    return (
      <div
        className={styles.tabcontent}
        style={{
          display: activeTab === tabIds.pendingRequests ? "flex" : "none",
        }}
      >
        {activeTab === tabIds.pendingRequests ? <PendingRequests /> : ""}
      </div>
    );
  }
  function getWaitingRequestsView() {
    return (
      <div
        className={styles.tabcontent}
        style={{
          display: activeTab === tabIds.waitingRequests ? "flex" : "none",
        }}
      >
        {activeTab === tabIds.waitingRequests ? <WaitingRequests /> : ""}
      </div>
    );
  }
  function getRejectedRequestsView() {
    return (
      <div
        className={styles.tabcontent}
        style={{
          display: activeTab === tabIds.rejectedRequests ? "flex" : "none",
        }}
      >
        {activeTab === tabIds.rejectedRequests ? <RejectedRequests /> : ""}
      </div>
    );
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  if (!auth.isAuthenticated) {
    return <></>;
  } else {
    return (
      <div className={styles.tabsContainer}>
        {showLoaderIfNeeds()}
        {getTopTabButtonsRow()}
        {getConnectedFriendsView()}
        {getBlockedFriendsView()}
        {getPendingRequestsView()}
        {getWaitingRequestsView()}
        {getRejectedRequestsView()}
      </div>
    );
  }
}

export default FriendAndRequests;
// We will show
//Sent(Waiting): Tab which will have Waiting only requests cancelling them will delete them.

// Received(Pending): I till have Pending reqs, accepting them will also delete and now the person is a friend
//May be we would have a separate table for that or the status is just fine.
//Rejecting the req will go to Completed tab

//A separate table may be for Blocked, who has blocked who, so that they won't appear in the search
//Blocked: this tab will show persons I have blocked. Blocked person will just disappear from friend
//to the requestor the status of request will show frozen, so he can delete such request
//so that friendship won't happen
//all other should be ok like groom search should be ok, so that if required the user can get motivation to unblock
//the target, because target got grooms or bride as their familymembers.
//Completed: this will have rejected by me, I can accept it again or block.
//If others rejected then we can resend them and they will go in waiting, or we can delete them.
//Connected: will hve the friends. Delete and it will be just deleted and now free to start all over again.

//todo In the tabs, correct the scrolling if there are many persons
