import EditableField from "./editableEmail/EditableField";
import PendingEmail from "./pendingEmail/PendingEmail";
import { BASE_URL } from "../../../../Constants";
import { doLogout, getAuthToken } from "../../../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { setPendingEmail } from "../../../../store/reducers/pendingEmailSlice";
import { useEffect } from "react";
import { authActions } from "../../../../store/reducers/authSlice";
function EmailField(props) {
  console.log("props in EmailField = ", props);
  const dispatch = useDispatch();
  const pendingEmail = useSelector((state) => state.pendingEmail.pendingEmail);
  console.log("pendingEmail from redux = ", pendingEmail);
  const updateNewEmail = (newEmail) => {
    console.log("setting up new pending email to ", newEmail);
    dispatch(setPendingEmail({ pendingEmail: newEmail }));
    if (newEmail !== "") {
      window.alert("Please verify your email by clicking on verify...");
      apiCall_setNewPendingEmail(newEmail);
    }
  };
  useEffect(() => {
    if ("pendingEmail" in props.registrationDetails !== "") {
      dispatch(
        setPendingEmail({
          pendingEmail: props.registrationDetails.pendingEmail,
        })
      );
    }
  }, [dispatch, props.registrationDetails]);
  const apiCall_setNewPendingEmail = async (newEmail) => {
    try {
      let url = BASE_URL + "/normalUser/updateEmail";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          email: newEmail,
        }),
      });
      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        }
      }
      console.log("response is = ", response);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    } finally {
      console.log("end loding here");
    }
  };
  return (
    <>
      <label>Email:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableField
        initialFieldValue={props.registrationDetails.email}
        updateFieldValue={updateNewEmail}
        placeholder="Type new email here"
        heading="Enter new email id:"
      />
      {!pendingEmail || pendingEmail === "" ? "" : <PendingEmail />}
    </>
  );
}
export default EmailField;
