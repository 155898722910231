import EditableField from "./editableEmail/EditableField";
import PendingPhone from "./pendingPhone/PendingPhone";
import { BASE_URL } from "../../../../Constants";
import { doLogout, getAuthToken } from "../../../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { setPendingPhone } from "../../../../store/reducers/pendingPhoneSlice";
import { useEffect } from "react";
import { authActions } from "../../../../store/reducers/authSlice";
function PhoneField(props) {
  console.log("props in PhoneField = ", props);
  const dispatch = useDispatch();
  const pendingPhone = useSelector((state) => state.pendingPhone.pendingPhone);
  console.log("pendingPhone from redux = ", pendingPhone);
  const updateNewPhone = (newPhone) => {
    console.log("setting up new pending email to ", newPhone);
    dispatch(setPendingPhone({ pendingPhone: newPhone }));
    if (newPhone !== "") {
      window.alert("Please verify your email by clicking on verify...");
      apiCall_setNewPendingPhone(newPhone);
    }
  };
  useEffect(() => {
    if ("pendingPhone" in props.registrationDetails !== "") {
      dispatch(
        setPendingPhone({
          pendingPhone: props.registrationDetails.pendingPhone,
        })
      );
    }
  }, [dispatch, props.registrationDetails]);
  const apiCall_setNewPendingPhone = async (newPhone) => {
    try {
      let url = BASE_URL + "/normalUser/updatePhone";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          phone: newPhone,
        }),
      });
      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        }
      }
      console.log("response is = ", response);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    } finally {
      console.log("end loding here");
    }
  };
  return (
    <>
      <label>Phone:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableField
        initialFieldValue={props.registrationDetails.phone}
        updateFieldValue={updateNewPhone}
        placeholder="Type new phone here"
        heading="Enter new phone:"
      />
      {!pendingPhone || pendingPhone === "" ? "" : <PendingPhone />}
    </>
  );
}
export default PhoneField;
