import RootLayout from "../pages/RootLayout";
import LoginPage, { action as loginAction } from "../pages/LoginPage";
import ErrorPage from "../pages/ErrorPage";
import Logout from "../pages/Logout";
import Register, {
  action as submitRegisterAction,
} from "../pages/register/Register";
import Welcome from "../pages/Welcome";
import Dashboard from "../pages/dashboard/Dashboard";
import Help from "../pages/Help";
import News from "../pages/news/News";
import CaptureImage from "../components/camera/CaptureImage";
import {
  keepOnWelcomeIfNoToken,
  keepOnDashboardIfLoggedIn,
  keepOnDashboardOrWelcome,
  keepOnWelcomeIfNoTokenElseGetProfileData,
  keepOnWelcomeIfNoTokenElseGetSettings,
  keepOnWelcomeIfNoTokenElseSearchPeople,
  keepOnWelcomeIfNoTokenElseFetchSavedGroomLists,
  keepOnWelcomeIfNoTokenElseSearchGroomsByCriteria,
  keepOnWelcomeIfNoTokenElseGetFamilyMembers,
  keepOnWelcomeIfNoTokenElseGetFriends,
  keepOnWelcomeIfNoTokenElseLoadListMembers,
} from "./auth";
import Settings from "../pages/Settings";
import Profile from "../pages/ProfileLoader";
import ForgotPassword, {
  action as forgotPasswordAction,
} from "../pages/forgotPassword/ForgotPasword";
import SetRegistrationPassword, {
  action as setRegistrationPasswordAction,
} from "../pages/register/SetRegistrationPassword";
import SetNewPassword, {
  action as setNewPasswordAction,
} from "../pages/forgotPassword/SetNewPassword";
import FamilyMembersLoader from "../pages/familyMembers/FamilyMembersLoader";
import AddNewMember from "../components/allFamilyMembers/AddNewMember";
import EditMember from "../components/allFamilyMembers/EditMember";
import FriendsLoader from "../pages/friends/FriendsLoader";
import SearchPeople from "../components/friendsAndRequests/searchPeopleToConnect/SearchPeopleCriteria";
import SearchPeopleCriteria from "../components/friendsAndRequests/searchPeopleToConnect/SearchPeopleCriteria";
import SearchedPeopleLoader from "../components/friendsAndRequests/searchPeopleToConnect/SearchedPeopleLoader";
import GroomListsLoader from "../pages/searchGroom/GroomListsLoader";
import SearchGroomCriteria from "../pages/searchGroom/SearchGroomCriteria";
import SearchedGroomLoader from "../pages/searchGroom/SearchedGroomLoader";
import BrowserLatLong from "../components/browserLatLong/BrowserLatLong";
import ListMemberLoader from "../pages/listMembers/ListMemberLoader";

const getRouter = (
  dispatch,
  userProfile,
  familyMembers,
  friends,
  reduxSavedGroomLists
) => {
  const router = [
    {
      path: "/",
      element: <RootLayout />,
      id: "root",
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: keepOnDashboardOrWelcome, //no need of any child component just do redirect
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/familyMembers",
          id: "familyMembers",
          element: <FamilyMembersLoader />,
          loader: () =>
            keepOnWelcomeIfNoTokenElseGetFamilyMembers(dispatch, familyMembers),
        },
        {
          path: "/familyMembers/addNewMember",
          element: <AddNewMember />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/familyMembers/editMember",
          element: <EditMember />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/forgotPassword",
          element: <ForgotPassword />,
          //should be accessible only if logged out
          loader: keepOnDashboardIfLoggedIn,
          action: forgotPasswordAction,
        },
        {
          path: "/forgotPassword/setNewPassword",
          element: <SetNewPassword />,
          //should be accessible only if logged out
          loader: keepOnDashboardIfLoggedIn,
          action: setNewPasswordAction,
        },
        {
          path: "/friends",
          id: "friends",
          element: <FriendsLoader />,
          loader: () => keepOnWelcomeIfNoTokenElseGetFriends(dispatch),
        },
        {
          path: "/friends/searchPeople",
          element: <SearchPeople />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/help",
          element: <BrowserLatLong />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/listMembers/:listId",
          id: "listMembers",
          element: <ListMemberLoader />,
          loader: ({ request, params }) =>
            keepOnWelcomeIfNoTokenElseLoadListMembers(
              dispatch,
              reduxSavedGroomLists,
              request,
              params
            ),
        },
        {
          path: "/login",
          element: <LoginPage />,
          loader: keepOnDashboardIfLoggedIn,
          action: loginAction,
        },
        { path: "/logout", element: <Logout /> },
        {
          path: "/news",
          element: <News />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/news1",
          element: <CaptureImage />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/profile",
          id: "profile",
          element: <Profile />,
          loader: () =>
            keepOnWelcomeIfNoTokenElseGetProfileData(dispatch, userProfile),
        },
        {
          path: "/register",
          element: <Register />,
          loader: keepOnDashboardIfLoggedIn,
          action: submitRegisterAction,
        },
        {
          path: "/register/setRegistrationPassword",
          element: <SetRegistrationPassword />,
          loader: keepOnDashboardIfLoggedIn,
          action: setRegistrationPasswordAction,
        },
        {
          path: "/searchedPeople",
          id: "searchedPeople",
          element: <SearchedPeopleLoader />,
          loader: () => keepOnWelcomeIfNoTokenElseSearchPeople(dispatch),
        },
        {
          path: "/searchGroom",
          id: "searchGroom",
          element: <GroomListsLoader />,
          loader: () =>
            keepOnWelcomeIfNoTokenElseFetchSavedGroomLists(
              dispatch,
              reduxSavedGroomLists
            ),
        },
        {
          path: "/searchedGrooms",
          id: "searchedGrooms",
          element: <SearchedGroomLoader />,
          loader: () =>
            keepOnWelcomeIfNoTokenElseSearchGroomsByCriteria(dispatch),
        },
        {
          path: "/searchGroomCriteria",
          element: <SearchGroomCriteria />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/searchPeopleCriteria",
          element: <SearchPeopleCriteria />,
          loader: keepOnWelcomeIfNoToken,
        },
        {
          path: "/settings",
          id: "settings",
          element: <Settings />,
          loader: () => keepOnWelcomeIfNoTokenElseGetSettings(dispatch),
        },
        {
          path: "/welcome",
          element: <Welcome />,
          loader: keepOnDashboardIfLoggedIn,
        },
      ],
    },
  ];
  return router;
};
export default getRouter;
