// authSaga.js
import { takeLatest, put } from "redux-saga/effects";
import { authActions } from "../reducers/authSlice"; // Import your authSlice actions
import { apiLogin } from "../../api/api"; // Import your login/logout API functions
import { BASE_URL } from "../../Constants";

function* loginSaga(action) {
  try {
    const { username, password } = action.payload;
    const response = yield apiLogin(username, password); // Call your login API

    // Assuming the API returns a token on success
    const token = response.token;

    // Save token in localStorage if needed
    localStorage.setItem("token", token);

    // Dispatch login success action with user data if available
    yield put(authActions.loginSuccess({ token }));
  } catch (error) {
    // Dispatch login failure action on error
    yield put(authActions.loginFailure());
  }
}

function* logoutSaga() {
  try {
    console.log("logout saga api is called..");
    const token = localStorage.getItem("token");
    console.log("token in logout: ", token);
    let url = BASE_URL + "/token/logout";

    // const response = yield fetch(url, {
    yield fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      // mode: "no-cors",
    });
  } catch (error) {
    // Handle logout failure if needed
    // For example, you might not dispatch an action on logout failure, or show an error message
    console.log("error during logout: ", error);
  } finally {
    // Dispatch logout success action
    yield put(authActions.logoutSuccess());

    // Remove token from localStorage if needed
    localStorage.removeItem("token");
  }
}

export default function* authSaga() {
  // Watch for loginRequest and logoutRequest actions and trigger respective Sagas
  yield takeLatest(authActions.loginRequest.type, loginSaga);
  yield takeLatest(authActions.logoutRequest.type, logoutSaga);
}
