import React, { useRef, useState } from "react";
import styles from "./CaptureImage.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import ImageCropper from "./ImageCropper";

function CaptureImage({ setIsDefaultImageUsed, setProfileImage, goBack }) {
  const fileFromGalleryRef = useRef(null);
  const fileInputRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const handleCapture = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCapturedImage(imageUrl);
    }
  };

  const handleOpenGalleryButtonClick = () => {
    // Trigger click event of the input element
    fileFromGalleryRef.current.click();
  };

  const handleFileChangeForGallery = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imgDataUrl = reader.result;
        // Do something with the selected image data URL
        console.log("Selected image:", imgDataUrl);
        setCapturedImage(imgDataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.buttonPanel}>
      <div className={styles.topPanel}>
        <button
          className={`${commonStyles.greenButton} ${styles.buttonPanel} `}
          onClick={handleCapture}
        >
          <i
            className="fa fa-2x fa-camera"
            style={{ color: "black" }}
            fa-xlarge
            aria-hidden="true"
          ></i>
          Open Camera
        </button>
        <div>
          <input
            ref={fileFromGalleryRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChangeForGallery}
          />
          <button
            className={`${commonStyles.yellowButton} ${styles.buttonPanel} `}
            onClick={handleOpenGalleryButtonClick}
          >
            <i
              style={{ color: "black" }}
              className="fa-2x fa-solid fa-folder"
            ></i>
            Open Gallery
          </button>
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {capturedImage && (
        <ImageCropper
          imageUrl={capturedImage}
          setProfileImage={setProfileImage}
          goBack={goBack}
          setIsDefaultImageUsed={setIsDefaultImageUsed}
        />
      )}
      <br />
      <button
        className={commonStyles.orangeButton}
        onClick={(e) => goBack(true)}
      >
        Cancel
      </button>
    </div>
  );
}

export default CaptureImage;
