// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const pendingEmailSlice = createSlice({
  name: "pendingEmail",
  initialState: {
    pendingEmail: "",
  },
  reducers: {
    clearPendingEmail: (state, action) => {
      state.pendingEmail = "";
    },
    setPendingEmail: (state, action) => {
      state.pendingEmail = action.payload.pendingEmail;
    },
  },
});

export default pendingEmailSlice.reducer;
export const { clearPendingEmail, setPendingEmail } = pendingEmailSlice.actions;
