import { BASE_URL } from "../Constants";

// api.js
export const apiLogin = async (username, password) => {
  // Simulate an asynchronous API call with a delay of 5 seconds
  return new Promise((resolve) => {
    setTimeout(() => {
      // Mock response with a random string as token
      const token = generateRandomToken();
      resolve({ token });
    }, 3000);
  });
};

export const apiLogout = async () => {
  // Simulate an asynchronous API call with a delay of 5 seconds
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     // Mock response with a boolean value (true for success)
  //     resolve(true);
  //   }, 1000);
  // });

  console.log("logout saga api is called..");
  const token = localStorage.getItem("token");
  console.log("token in logout: ", token);
  let url = BASE_URL + "/token/logout";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    },
  });

  if (!response.ok) {
    // throw json({ message: "logout failed." }, { status: 500 });
    console.log("logout failed......");
  } else {
    console.log("logout success...");
  }
};

// Helper function to generate a random string (mock token)
const generateRandomToken = () => {
  const tokenLength = 12;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomToken = "";

  for (let i = 0; i < tokenLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomToken += characters.charAt(randomIndex);
  }

  return randomToken;
};
