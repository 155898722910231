import ReactDOM from "react-dom";
import { listColors } from "../../Constants";
import styles from "./News.module.scss";
import { BackDrop } from "../../components/UI/ErrorModal";
import { useEffect, useRef, useState } from "react";

function News() {
  const [isEditing, setIsEditing] = useState("");

  function getEditButtonPanel() {
    if (!isEditing) {
      return (
        <button
          type="button"
          onClick={(e) => {
            setIsEditing("1");
          }}
        >
          EDIT
        </button>
      );
    } else {
      return (
        <>
          <button
            type="button"
            onClick={(e) => {
              setIsEditing("");
            }}
          >
            SAVE
          </button>
          <button
            type="button"
            onClick={(e) => {
              setIsEditing("");
            }}
          >
            Cancel
          </button>
        </>
      );
    }
  }
  return <div style={{ color: "black" }}>{getEditButtonPanel()}</div>;
}

function News2() {
  const [showBd, setShowBd] = useState("");
  const [scrollPosition, setScrollPosition] = useState("");
  const pRef = useRef(null);
  useEffect(() => {
    if (pRef.current) {
      pRef.current.scrollTop = scrollPosition;
    }
  }, [showBd, scrollPosition]);

  function TestComponent() {
    return (
      <>
        <div>This is the dialog</div>
      </>
    );
  }
  function BakDrp() {
    return (
      <>
        {ReactDOM.createPortal(
          <BackDrop
            onConfirm={(e) => {
              setShowBd("");
            }}
          />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <TestComponent />,
          document.getElementById("overlay-root")
        )}
      </>
    );
  }
  function onShowClick(e) {
    console.log("show clicked");
    // setShowBd("1");
  }

  return (
    <div
      ref={pRef}
      className={showBd ? styles.disableScroll : styles.enableScroll}
    >
      <div>
        <div
          onClick={(e) => {
            setShowBd((prevState) => {
              console.log("scroll= ", pRef.current, pRef.current.scrollTop);
              if (pRef.current) {
                setScrollPosition(pRef.current.scrollTop);
              }
              if (prevState == "") {
                console.log("switch on");
                return "1";
              } else {
                console.log("switch off");
                return "";
              }
            });
            console.log("top div is clicked");
          }}
          style={{
            position: "fixed", //take out of normal flow
            zIndex: "4",
            top: "-20%", //to cover navigation bar also
            width: "100%",
            height: "80vh", // should be 120 to compensate top displacement
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          This is top div
        </div>
        <div>
          <div>This is a div</div>
          <button type="button">some other button</button>
          <img
            style={{ width: "200px" }}
            src="https://easydrawingart.com/wp-content/uploads/2022/10/how-to-draw-1.jpg.webp"
          />
          <img
            style={{ width: "200px" }}
            src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA5L3Jhd3BpeGVsX29mZmljZV8xMF8zZF9jaGFyYWN0ZXJfb2ZfYV9ib3lfY2FycnlfYV90b25fb2Zfcm9zZXNfdF80NDA5MTY2OS1iODY3LTRhYjctOTEzYy1iY2MwMDQ1ODVjYWUucG5n.png"
          />
          <button onClick={onShowClick} type="button">
            show
          </button>
          <img
            style={{ width: "200px" }}
            src="https://i.pinimg.com/736x/6b/30/9a/6b309a2ccde102f45ef6c4e5c3f40052.jpg"
          />
          <img
            style={{ width: "200px" }}
            src="https://w.forfun.com/fetch/50/5081f28733ce69397d496a2dfdcedc2d.jpeg"
          />
          <img
            style={{ width: "200px" }}
            src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA5L3Jhd3BpeGVsX29mZmljZV8xMF8zZF9jaGFyYWN0ZXJfb2ZfYV9ib3lfY2FycnlfYV90b25fb2Zfcm9zZXNfdF80NDA5MTY2OS1iODY3LTRhYjctOTEzYy1iY2MwMDQ1ODVjYWUucG5n.png"
          />
          <img
            style={{ width: "200px" }}
            src="https://i.pinimg.com/736x/6b/30/9a/6b309a2ccde102f45ef6c4e5c3f40052.jpg"
          />
        </div>
      </div>
    </div>
  );
}

function News1() {
  return (
    <>
      {listColors.map((item) => (
        <div
          key={item}
          className={styles.boxStyle}
          style={{ backgroundColor: item }}
        >
          {item}
        </div>
      ))}
    </>
  );
}

export default News;
