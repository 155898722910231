export function isAPhoneNumber(input) {
  // Regular expression to match "+91" followed by 10 digits
  const regex = /^\+91[0-9]{10}$/;
  const result = regex.test(input);
  return result;
}

export function isAnEmail(emailInput) {
  const email = emailInput.toLowerCase();
  if (email.length < 5) {
    alert("Input length must be at least 5");
    return false; //a@b.c
  }
  if (email.length > 100) {
    alert("Input should have <100 length.");
    return false;
  }
  const emailParts = email.split("@");
  if (emailParts.length !== 2) {
    alert("onlye one @ allowed in email.");
    return false; //must have only one @, so 2 parts left and right
  }
  const withoutUnderscore = removeAllOccurrences(email, "_");
  const withoutAt = removeAllOccurrences(withoutUnderscore, "@");
  const withoutDot = removeAllOccurrences(withoutAt, "\\.");
  const withoutHyphen = removeAllOccurrences(withoutDot, "-");
  const withAlphaAndDigit = withoutHyphen;
  const regExForAlphaDigit = /^[a-z0-9]+$/;
  if (
    withAlphaAndDigit.length > 0 &&
    !regExForAlphaDigit.test(withAlphaAndDigit)
  ) {
    alert(
      "only alphabets, digits, hyphen(-), underscore(_), dot(.) and @ allowed in email."
    );
    return false;
  }

  // const leftPart = emailParts[0];
  const rightPart = emailParts[1];
  const domainParts = rightPart.split(".");
  if (domainParts.length !== 2) {
    alert("only one dot allowed after @ in email");
    //may be later we will allow multiple dots to support modi@nic.gov.in type mails
    return false; //only one dot allowed
  }

  const onlyLowercaseAlphaRegEx = /^[a-z]+$/;
  if (!onlyLowercaseAlphaRegEx.test(domainParts[1])) {
    alert("only alphaets allowed after . (after @) in email");
  }

  return true;
}
function removeAllOccurrences(inputString, charToRemove) {
  //use \\ for . + to remove
  const regex = new RegExp(charToRemove, "g"); //g is for global option in regex
  return inputString.replace(regex, "");
}
