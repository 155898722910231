import { useNavigate } from "react-router-dom";
import AllMembersGridView from "./AllMembersGridView";
import { doNavigate } from "../../util/auth";

function AllFamilyMembers(props) {
  console.log("In AllFamilyMembers props: ", props);
  console.log("props.data = ", props.data);
  //todo check props and navigate if invalid token
  const navigate = useNavigate();
  const onAddMemberClick = (e) => {
    doNavigate(navigate, "/familyMembers/addNewMember");
  };
  // localStorage.removeItem(CREATING_NEW_MEMBER);
  return (
    <>
      {props.data ? (
        <AllMembersGridView data={props.data} />
      ) : (
        <div>
          <label>You don't have any family member added.</label>
          <button type="button" onClick={onAddMemberClick}>
            Add Members Now
          </button>
        </div>
      )}
    </>
  );
}

export default AllFamilyMembers;
