import { createSlice } from "@reduxjs/toolkit";

const familyMembersSlice = createSlice({
  name: "familyMembers",
  initialState: { members: [] },
  reducers: {
    //we will call this reducer only when a member is deleted
    fetchMembersSuccess(state, action) {
      const members = action.payload;
      console.log("inside fetchMembersSuccess,action = ", action);
      state.members = members;
    },
    clearFamilyMembers(state, action) {
      state.members = [];
    },
  },
});

export default familyMembersSlice.reducer;
export const { fetchMembersSuccess, clearFamilyMembers } =
  familyMembersSlice.actions;
