// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  SOURCE_REQUESTED,
  TARGET_ACCEPTED,
  TARGET_BLOCKED,
  TARGET_REJECTED,
} from "../../Constants";
import { loadState } from "../../util/localStorageReader";
//-------------------------------------------------------------
const emptyFriends = {
  connectedFriends: { elements: [] },
  pendingRequests: { elements: [] },
  waitingRequests: { elements: [] },
  rejectedRequests: { elements: [] },
  blockedPersons: { elements: [] },
  searchedPersons: { elements: [] },
};
let oldSavedFriends = emptyFriends;
const stateBeforeRefresh = loadState();
if (stateBeforeRefresh.friends) {
  oldSavedFriends = stateBeforeRefresh.friends;
}

function insertUniqueElements(obj, friends) {
  const e = friends.elements;
  if (e.length > 0) {
    const idSet = new Set(obj.elements.map((e) => e.id));

    for (let i = 0; i < e.length; i++) {
      if (!idSet.has(e[i].id)) {
        idSet.add(e[i].id);
        obj.elements.push(e[i]); //concat does not mutate existing array, push does
      } else {
        //update existing obj if new keys are added to the person
        const existingPerson = obj.elements.find(
          (element) => element.id === e[i].id
        );
        Object.keys(e[i]).forEach((key) => {
          existingPerson[key] = e[i][key];
        });
      }
    }
  } //if
}
//-------------------------------------------------------------
function updateElementsAndOtherKeys(obj, friends) {
  console.log("got friends = ", friends);
  if (obj.elements.length < 1) {
    obj.elements = friends.elements;
  } else {
    insertUniqueElements(obj, friends);
  }
  //overwrite other values
  Object.keys(friends).forEach((key) => {
    if (key !== "elements") {
      obj[key] = friends[key];
    }
  });
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
  obj.lastFetchedOn = currentTimestampInSeconds;
  //just adjust the total count for now, it will be refreshed automatically when they will come back.
  //or if all the elements are deleted, then just show whatever we have as total
  //or if the elements are just reduced to be what we are showing already then also show the total of what we are showing
  if (
    obj.elements.length > obj.totalElements ||
    friends.totalElements == 0 ||
    friends.totalElements < obj.elements.length
  ) {
    obj.totalElements = obj.elements.length;
  }
}
//-------------------------------------------------------------
function movePersonFromSearchedPersonsToWaitingRequests(
  searchedPersons,
  friendRequests,
  waitingRequests
) {
  //for now we are processing only one member, because we send request for only 1 member
  console.log(
    "In movePersonFromSearchedPersonsToWaitingRequests, searched=" +
      searchedPersons.elements +
      ", friendRequests=",
    friendRequests
  );
  const newReq = friendRequests.elements[0];
  const personToMove = searchedPersons.elements.find(
    (p) => p.id == newReq.targetMemberId
  );

  personToMove["friendRequestId"] = newReq.id;
  personToMove["friendRequestStatus"] = newReq.status;
  personToMove["friendRequestUpdatedOn"] = newReq.updatedOn;
  searchedPersons.elements = searchedPersons.elements.filter(
    (e) => e.id != newReq.targetMemberId
  );
  searchedPersons.totalElements = parseInt(searchedPersons.totalElements) - 1;
  waitingRequests.elements.push(personToMove);
  waitingRequests["totalElements"] =
    parseInt(waitingRequests["totalElements"]) + 1;
}
//-------------------------------------------------------------
function moveFriendReqFromListA2B(a, friendReq, b, newStatus) {
  removeFriendReqFromList(a, friendReq);
  friendReq.friendRequestStatus = newStatus;
  friendReq.friendRequestUpdatedOn = Math.floor(Date.now() / 1000);
  if (b.elements.length > 0) {
    //push to existing list only if we already have some elements,
    //this is to make sure that if the request is not yet fetched, it will be fetched from server
    //so that it will show items already on server
    appendReqToList(b, friendReq);
  }
}
//-------------------------------------------------------------
function removeFriendReqFromList(reqList, friendReq) {
  reqList.elements = reqList.elements.filter((e) => e.id != friendReq.id);
  reqList.totalElements = parseInt(reqList.totalElements) - 1; //totalElements is NOT the currently visible element, it is received from backend
}
function appendReqToList(reqList, friendReq) {
  reqList.elements.push(friendReq);
  reqList["totalElements"] = parseInt(reqList["totalElements"]) + 1;
}
//-------------------------------------------------------------
const friendsSlice = createSlice({
  name: "friends",
  initialState: oldSavedFriends,
  reducers: {
    fetchSearchedPersonsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchSearchedPersonsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.searchedPersons, friends);
    },
    fetchConnectedFriendsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchConnectedFriendsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.connectedFriends, friends);
    },
    fetchPendingRequestsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchPendingRequestsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.pendingRequests, friends);
    },
    fetchWaitingRequestsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchWaitingRequestsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.waitingRequests, friends);
    },
    fetchRejectedRequestsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchRejectedRequestsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.rejectedRequests, friends);
    },
    fetchBlockedPersonsSuccess(state, action) {
      const friends = action.payload;
      console.log("inside fetchBlockedPersonsSuccess, action = ", action);
      updateElementsAndOtherKeys(state.blockedPersons, friends);
    },
    sendingFriendRequestSuccess(state, action) {
      const friendRequests = action.payload;
      console.log("inside sendingFriendRequestSuccess, action = ", action);
      movePersonFromSearchedPersonsToWaitingRequests(
        state.searchedPersons,
        friendRequests,
        state.waitingRequests
      );
    },
    rejectedRequestIsDeleted(state, action) {
      const friendReq = action.payload;
      console.log("inside rejectedRequestIsDeleted, action = ", action);
      removeFriendReqFromList(state.rejectedRequests, friendReq);
    },
    rejectedRequestIsAccepted(state, action) {
      const friendReq = action.payload;
      console.log("inside rejectedRequestIsAccepted, action = ", action);
      moveFriendReqFromListA2B(
        state.rejectedRequests,
        friendReq,
        state.connectedFriends,
        TARGET_ACCEPTED
      );
    },
    rejectedRequestIsBlocked(state, action) {
      const friendReq = action.payload;
      console.log("inside rejectedRequestIsBlocked, action = ", action);
      moveFriendReqFromListA2B(
        state.rejectedRequests,
        friendReq,
        state.blockedPersons,
        TARGET_BLOCKED
      );
    },
    rejectedRequestIsResent(state, action) {
      const friendReq = action.payload;
      console.log("inside rejectedRequestIsResent, action = ", action);
      moveFriendReqFromListA2B(
        state.rejectedRequests,
        friendReq,
        state.waitingRequests,
        SOURCE_REQUESTED
      );
    },
    waitingRequestIsDeleted(state, action) {
      const friendReq = action.payload;
      console.log("inside waitingRequestIsDeleted, action = ", action);
      removeFriendReqFromList(state.waitingRequests, friendReq);
    },
    pendingRequestIsAccepted(state, action) {
      const friendReq = action.payload;
      console.log("inside pendingRequestIsAccepted, action = ", action);
      moveFriendReqFromListA2B(
        state.pendingRequests,
        friendReq,
        state.connectedFriends,
        TARGET_ACCEPTED
      );
    },
    pendingRequestIsRejected(state, action) {
      const friendReq = action.payload;
      console.log("inside pendingRequestIsRejected, action = ", action);
      moveFriendReqFromListA2B(
        state.pendingRequests,
        friendReq,
        state.rejectedRequests,
        TARGET_REJECTED
      );
    },
    pendingRequestIsBlocked(state, action) {
      //for blockedMember, We will save the friendReq, but during unblock, we will
      //pass the targetMemberId to unblock.
      const friendReq = action.payload;
      console.log("inside pendingRequestIsBlocked, action = ", action);
      moveFriendReqFromListA2B(
        state.pendingRequests,
        friendReq,
        state.blockedPersons
      );
    },
    blockedPersonIsUnblocked(state, action) {
      const friendReq = action.payload;
      console.log("inside blockedPersonIsUnblocked, action = ", action);
      removeFriendReqFromList(state.blockedPersons, friendReq);
    },
    connectedFriendIsDeleted(state, action) {
      const friendReq = action.payload;
      console.log("inside connectedFriendIsDeleted, action = ", action);
      removeFriendReqFromList(state.connectedFriends, friendReq);
    },
    clearFriends(state, action) {
      Object.keys(emptyFriends).forEach((key) => {
        state[key] = emptyFriends[key];
      });
    },
    clearedSearchedPersons(state, action) {
      console.log("inside clearedSearchedPersons, action = ", action);
      state.searchedPersons = { elements: [] };
    },
  },
});

export default friendsSlice.reducer;
export const {
  fetchSearchedPersonsSuccess,
  fetchConnectedFriendsSuccess,
  fetchPendingRequestsSuccess,
  fetchWaitingRequestsSuccess,
  fetchRejectedRequestsSuccess,
  fetchBlockedPersonsSuccess,
  sendingFriendRequestSuccess,
  rejectedRequestIsDeleted,
  rejectedRequestIsAccepted,
  rejectedRequestIsBlocked,
  rejectedRequestIsResent,
  waitingRequestIsDeleted,
  pendingRequestIsAccepted,
  pendingRequestIsRejected,
  pendingRequestIsBlocked,
  blockedPersonIsUnblocked,
  connectedFriendIsDeleted,
  clearedSearchedPersons,
  clearFriends,
} = friendsSlice.actions;
