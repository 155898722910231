import EditableField from "./editableEmail/EditableField";
import { BASE_URL } from "../../../../Constants";
import { getAuthToken } from "../../../../util/auth";
import { useState } from "react";
function PasswordField(props) {
  console.log("props in PasswordField = ", props);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateNewPassword = (newPassword) => {
    if (newPassword !== "") {
      apiCall_setNewPassword(newPassword);
    }
  };
  const apiCall_setNewPassword = async (newPassword) => {
    setIsEditing(false);
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateRegistrationDetails";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          password: newPassword,
        }),
      });

      console.log("response is = ", response);
    } catch (error) {
      console.error("Error during POST request:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <label>Password:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableField
        initialFieldValue="**********"
        updateFieldValue={updateNewPassword}
        placeholder="Type new password here"
        heading="Enter new password:"
      />
    </>
  );
}
export default PasswordField;
