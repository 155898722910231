import ImageButton from "../../components/UI/imageButton/ImageButton";
import familyIconImageUrl from "../../assets/familyIcon.png";
import connectionsIconImageUrl from "../../assets/connectionsIcon.png";
import searchBrideIconImageUrl from "../../assets/searchBrideIcon.png";
import searchGroomIconImageUrl from "../../assets/searchGroomIcon.png";
import helpIconImageUrl from "../../assets/helpIcon.png";
import settingIconImageUrl from "../../assets/settingsIcon.png";
import styles from "./Dashboard.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SEARCH_GENDER } from "../../Constants";
import { Loader } from "../../components/UI/ErrorModal";
import { doNavigate } from "../../util/auth";
function Dashboard() {
  const navigate = useNavigate();
  //to make sure we show image first and then the button it
  const [familyIconLoaded, setFamilyIconLoaded] = useState("");
  const [connectionIconLoaded, setConnectionIconLoaded] = useState("");
  const [groomIconLoaded, setGroomIconLoaded] = useState("");
  const [brideIconLoaded, setBrideIconLoaded] = useState("");
  const [settingIconLoaded, setSettingIconLoaded] = useState("");
  const [helpIconLoaded, setHelpIconLoaded] = useState("");
  const [message, setMessage] = useState("");
  function atLeaseOneIconLoading() {
    return !(
      familyIconLoaded &&
      connectionIconLoaded &&
      groomIconLoaded &&
      brideIconLoaded &&
      settingIconLoaded &&
      helpIconLoaded
    );
  }
  function decodeToken() {
    const token = localStorage.getItem("token");
    const arrayToken = token.split(".");
    // console.log("arrayToken=", JSON.parse(atob(arrayToken[1])));
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    console.log("tokenPayload = ", tokenPayload);
    localStorage.setItem("userId", tokenPayload.userId);
    localStorage.setItem("selfMemberId", tokenPayload.selfMemberId);
  }
  decodeToken();
  const familyIconClicked = (event) => {
    doNavigate(navigate, "/familyMembers");
  };
  const connectionsIconClicked = (event) => {
    doNavigate(navigate, "/friends");
  };
  const searchGroomIconClicked = (event) => {
    localStorage.setItem(SEARCH_GENDER, "MALE");
    doNavigate(navigate, "/searchGroom");
  };
  const searchBrideIconClicked = (event) => {
    localStorage.setItem(SEARCH_GENDER, "FEMALE");
    doNavigate(navigate, "/searchGroom");
  };
  const settingIconClicked = (event) => {
    doNavigate(navigate, "/settings");
  };
  const helpIconClicked = (event) => {
    setMessage("help Clicked");
  };
  function getFamilyButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={familyIconImageUrl}
          onClick={familyIconClicked}
          onImageLoad={setFamilyIconLoaded}
        />
        {familyIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.familyBtnText}>Family</div>
          </div>
        )}
      </div>
    );
  }
  function getHelpButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={helpIconImageUrl}
          onClick={helpIconClicked}
          onImageLoad={setHelpIconLoaded}
        />
        {helpIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.helpBtnText}>Help</div>
          </div>
        )}
      </div>
    );
  }
  function getSettingsButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={settingIconImageUrl}
          onClick={settingIconClicked}
          onImageLoad={setSettingIconLoaded}
        />
        {settingIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.settingsBtnText}>Settings</div>
          </div>
        )}
      </div>
    );
  }
  function getBrideButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={searchBrideIconImageUrl}
          onClick={searchBrideIconClicked}
          onImageLoad={setBrideIconLoaded}
        />
        {brideIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.brideBtnText}>Search Bride</div>
          </div>
        )}
      </div>
    );
  }
  function getGroomButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={searchGroomIconImageUrl}
          onClick={searchGroomIconClicked}
          onImageLoad={setGroomIconLoaded}
        />
        {groomIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.groomBtnText}>Search Groom</div>
          </div>
        )}
      </div>
    );
  }
  function getConnectionsButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          imageUrl={connectionsIconImageUrl}
          onClick={connectionsIconClicked}
          onImageLoad={setConnectionIconLoaded}
        />
        {connectionIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.connectionsBtnText}>Connections</div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={styles.dashboardContainer}>
      {atLeaseOneIconLoading() && <Loader />}
      {getFamilyButton()}
      {getConnectionsButton()}
      {getGroomButton()}
      {getBrideButton()}
      {getSettingsButton()}
      {getHelpButton()}
      <div>{message}</div>
    </div>
  );
}

export default Dashboard;
