import EditableMemberDetailsView from "./EditableMemberDetailsView";
import usePropsToGetFormData from "./MemberDetailsStates";
import CaptureImage from "../camera/CaptureImage";
import submitFormDetails from "./submitDetails";
import { Loader } from "../UI/ErrorModal";
import { useEffect, useState } from "react";
import { textFor } from "../../util/languages";
import { CREATING_NEW_MEMBER } from "../../Constants";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doNavigate } from "../../util/auth";
function MemberDetails(props) {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  //We can directly refer to redux state here instead of using props,
  //But props will be helpful in deciding which value is changed
  const {
    formData,
    handleChange,
    resetFormFields,
    clearFormFields,
    setIsDefaultImageUsed,
    setEnableCaptureImage,
    setProfileImage,
    setIsEditing,
  } = usePropsToGetFormData(props);
  const [isSubmitting, setIsSubmitting] = useState("");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  console.log("auth is = ", auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [props, navigate]);
  console.log("props in MemberDetails: ", props);
  // window.alert("In member details, ownerUserId=", props.ownerUserId);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (formData.fullName.trim().length < 1) {
      window.alert(textFor("nameIsMandatoryField"));
    } else {
      await submitFormDetails(
        props,
        formData,
        setIsEditing,
        setIsSubmitting,
        dispatch
      );
      //if the memberDetails are for creating new member, then move to allMembers view on completion
      if (localStorage.getItem(CREATING_NEW_MEMBER) === "SAVED") {
        console.log("Navigating to all family members");
        history.back();
      }
    }
  };
  const goBack = (value) => {
    //goBack(true) means disable capturing image
    setEnableCaptureImage(!value);
  };
  return (
    <>
      <>{isSubmitting && <Loader />} </>
      <>
        {formData.enableCaptureImage === true ? (
          <CaptureImage
            setIsDefaultImageUsed={setIsDefaultImageUsed}
            setProfileImage={setProfileImage}
            goBack={goBack}
          />
        ) : (
          <EditableMemberDetailsView
            {...{
              formData,
              handleChange,
              resetFormFields,
              clearFormFields,
              handleFormSubmit,
              setIsDefaultImageUsed,
              setProfileImage,
              setIsEditing,
              setEnableCaptureImage,
            }}
          />
        )}
      </>
    </>
  );
}

export default MemberDetails;
