import React from "react";
import styles from "./MemberDetails.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import Dropdown from "../UI/dropdown/Dropdown";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import yesNoOptionsJson from "../../jsonConstants/yesNo.json";
import genderTypeOptionsJson from "../../jsonConstants/genderTypes.json";
import educationOptionsJson from "../../jsonConstants/educationTypes.json";
import yearlyIncomeOptionsJson from "../../jsonConstants/yearlyIncome.json";
import maritalStatusOptionsJson from "../../jsonConstants/maritalStatusTypes.json";
import relationTypeOptionsJson from "../../jsonConstants/relationTypes.json";
import gotraTypesJson from "../../jsonConstants/gotraTypes.json";
import birthYearJson from "../../jsonConstants/birthYear.json";
import weightRangeJson from "../../jsonConstants/weightRange.json";
import heightFeetJson from "../../jsonConstants/heightFeet.json";
import heightInchJson from "../../jsonConstants/heightInch.json";
import CircularImage from "../UI/CircularImage/CircularImage";

const { yesNoOptions } = yesNoOptionsJson;
const { genderTypeOptions } = genderTypeOptionsJson;
const { educationOptions } = educationOptionsJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { gotraTypeOptions } = gotraTypesJson;
const { birthYearOptions } = birthYearJson;
const { weightRangeOptions } = weightRangeJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
const { relationTypeOptions } = relationTypeOptionsJson;

const EditableMemberDetailsView = ({
  formData,
  handleChange,
  resetFormFields,
  clearFormFields,
  handleFormSubmit,
  setIsDefaultImageUsed,
  setProfileImage,
  setIsEditing,
  setEnableCaptureImage,
}) => {
  console.log("formData in View:", formData);
  const useDefaultImageClicked = () => {
    setIsDefaultImageUsed(true);
    setProfileImage(userPlaceholderImageUrl);
  };
  // const handleGoBack = (e) => {
  //   doNavigate(navigate, "/familyMembers");
  // };
  const handleEditClick = (e) => {
    setIsEditing(true);
  };

  const handleCancelClick = (e) => {
    resetFormFields();
  };

  const handleClearClick = () => {
    clearFormFields();
  };

  const getNameBirthGenderGotraView = () => {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Name: </div>
          <input
            name="fullName"
            type="text"
            className={styles.inputField}
            placeholder="Full Name"
            disabled={!formData.isEditing}
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Birth Year :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="birthYear"
              message="Select birth year"
              label=""
              value={formData.birthYear}
              onChange={handleChange}
              options={birthYearOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Gender :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="genderType"
              message="Select Gender"
              label=""
              value={formData.genderType || ""}
              onChange={handleChange}
              options={genderTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Gotra :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="gotraType"
              message="Select Gotra"
              label=""
              value={formData.gotraType}
              onChange={handleChange}
              options={gotraTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
      </>
    );
  };
  function getFoodMarriageEducationView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Pure Vegitarian</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="isPureVegetarian"
              message="Select status"
              label=""
              value={formData.isPureVegetarian || ""}
              onChange={handleChange}
              options={yesNoOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Marital Status :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="maritalStatusType"
              message="Select Status"
              label=""
              value={formData.maritalStatusType || ""}
              onChange={handleChange}
              options={maritalStatusOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Education:</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="educationLevelType"
              message="Select Education"
              label=""
              value={formData.educationLevelType || ""}
              onChange={handleChange}
              options={educationOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        {formData.showEduDetails ? (
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabelMultiline}>
              <div>Education</div>
              <div>Details:</div>
            </div>
            <textarea
              name="educationDetails"
              rows={3}
              className={styles.inputField}
              placeholder="Optional details. 12th(Maths), B.Tech/M.Sc.(Branch), BCA, MBA, etc."
              disabled={!formData.isEditing}
              value={formData.educationDetails}
              onChange={handleChange}
            ></textarea>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  function getBodyIncomeOtherFieldsView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Approximate Weight :</div>*
          <div className={styles.dropdown}>
            <Dropdown
              name="weightRangeType"
              message="Weight Range"
              label=""
              value={formData.weightRangeType || ""}
              onChange={handleChange}
              options={weightRangeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Height :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="heightFeetType"
              message="Feet"
              label=""
              value={formData.heightFeetType || ""}
              onChange={handleChange}
              options={heightFeetOptions}
              disabled={!formData.isEditing}
            />
          </div>
          {/* <div className={styles.fieldLabel}>Feet, </div> */}
          <div className={styles.dropdown}>
            <Dropdown
              name="heightInchesType"
              message="Inches"
              label=""
              value={formData.heightInchesType || ""}
              onChange={handleChange}
              options={heightInchOptions}
              disabled={!formData.isEditing}
            />
          </div>
          {/* <div className={styles.fieldLabel}>Inches</div> */}
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Specially Abled</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="isSpeciallyAbled"
              message="Select status"
              label=""
              value={formData.isSpeciallyAbled || ""}
              onChange={handleChange}
              options={yesNoOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        {formData.showChallengeDetails ? (
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabelMultiline}>
              <div>Special Ability</div>
              <div>Details:</div>
            </div>
            <textarea
              name="specialAbilityDetails"
              rows={3}
              className={styles.inputField}
              placeholder="Optional details about physical challenge."
              disabled={!formData.isEditing}
              value={formData.specialAbilityDetails}
              onChange={handleChange}
            ></textarea>
          </div>
        ) : (
          ""
        )}

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Yearly Income</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="yearlyIncome"
              message="Yearly Income"
              label=""
              value={formData.yearlyIncome || ""}
              onChange={handleChange}
              options={yearlyIncomeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabelMultiline}>
            <div>Income Source</div>
            <div>Details:</div>
          </div>
          <textarea
            name="incomeSourceDetails"
            rows={3}
            className={styles.inputField}
            placeholder="Optional details about income source."
            disabled={!formData.isEditing}
            value={formData.incomeSourceDetails || ""}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabelMultiline}>
            <div>Any Other</div>
            <div>Details:</div>
          </div>
          <textarea
            name="otherDetails"
            rows={3}
            className={styles.inputField}
            placeholder="Optional details about anything else."
            disabled={!formData.isEditing}
            value={formData.otherDetails || ""}
            onChange={handleChange}
          ></textarea>
        </div>
      </>
    );
  }
  function getRelationshipTypeView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>Relation :</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="familyRelationType"
              message="Select Relation with you"
              label=""
              value={formData.familyRelationType}
              onChange={handleChange}
              options={relationTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
      </>
    );
  }
  function getImageAndChangeButtonsView() {
    return (
      <>
        <div className={commonStyles.topPanel} style={{ marginBottom: "10px" }}>
          <div style={{ fontWeight: "bold", color: "black" }}>
            Person Details:
          </div>
          {formData.isEditing ? (
            <>
              <button
                className={commonStyles.blackButton}
                type="button"
                onClick={handleClearClick}
              >
                Clear
              </button>
              <button className={commonStyles.greenButton} type="submit">
                Save
              </button>
              <button
                className={commonStyles.orangeButton}
                type="button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                className={commonStyles.topRightActionBtn}
                type="button"
                onClick={handleEditClick}
              >
                Edit
              </button>
              {/* <button
                className={commonStyles.blueButton}
                type="button"
                onClick={handleGoBack}
              >
                Go back
              </button> */}
            </>
          )}
        </div>
        <div className={styles.profileImageSection}>
          <CircularImage profileImageUrl={formData.profileImage} />
          {formData.isEditing ? (
            <>
              <button
                className={commonStyles.magentaButton}
                type="button"
                onClick={() => setEnableCaptureImage(true)}
              >
                Change
              </button>
              {!formData.isDefaultImageUsed && (
                <button
                  className={commonStyles.aquaButton}
                  type="button"
                  onClick={useDefaultImageClicked}
                >
                  Default
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
  return (
    <form onSubmit={handleFormSubmit}>
      <div className={styles.imageAndRightSideOptions}>
        {getImageAndChangeButtonsView()}
        {getNameBirthGenderGotraView()}

        {
          //When showing view for Self detaiils or userProfile, the relation will be SELF
          //it will be blank or other values for member
          formData.familyRelationType !== "SELF"
            ? getRelationshipTypeView()
            : ""
        }
        {getFoodMarriageEducationView()}

        {getBodyIncomeOtherFieldsView()}
      </div>
    </form>
  );
};

export default EditableMemberDetailsView;
