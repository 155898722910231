export function scrollTabToMakeFullyVisible(event) {
  const target = event.target.closest("button");
  const parentContainer = target.closest("button").closest("div"); // Get the parent container

  if (parentContainer) {
    const elementRect = target.getBoundingClientRect();
    const parentRect = parentContainer.getBoundingClientRect();

    // Calculate the amount to scroll
    let scrollAmount;
    if (elementRect.left < parentRect.left) {
      // Element is hidden on the left side
      scrollAmount = elementRect.left - parentRect.left;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else if (elementRect.right > parentRect.right) {
      // Element is hidden on the right side
      scrollAmount = elementRect.right - parentRect.right;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else {
      // Element is fully visible, no need to scroll.
      return;
    }
  }
}

export function getAge(m) {
  if (m.birthYear) {
    const currentYear = new Date().getFullYear();
    return currentYear - m.birthYear;
  }
  return "X";
}
