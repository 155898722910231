import React from "react";
import classes from "./Button.module.scss";
import commonStyles from "../../commonStyles.module.scss";

const Button = (props) => {
  // const emptyClick = ()=>{alert('Nothing on click...');}
  return (
    <button
      className={commonStyles.orangeButton}
      type={props.type || "button"}
      onClick={props.onClick}
      autoFocus={props.autoFocus}
    >
      {props.children}{" "}
    </button>
  );
};
export default Button;
