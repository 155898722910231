import { filter } from "lodash";
import {
  DISTANCE_RANGE,
  FILTER_DISTANCE_RANGE,
  FILTER_GOTRA_IN,
  FILTER_IS_PURE_VEG,
  FILTER_IS_SPECIALLY_ABLED,
  FILTER_MARITAL_STATUS_IN,
  FILTER_MAX_BIRTH_YEAR,
  FILTER_MAX_HEIGHT_FEET,
  FILTER_MAX_WEIGHT,
  FILTER_MIN_BIRTH_YEAR,
  FILTER_MIN_EDUCATION_LEVEL,
  FILTER_MIN_HEIGHT_FEET,
  FILTER_MIN_HEIGHT_INCHES,
  FILTER_MIN_WEIGHT,
  FROM_HEIGHT_FEET,
  FROM_HEIGHT_INCHES,
  GOTRA,
  KUNDLI_DOSHA,
  MARITAL_STATUS,
  MAX_AGE,
  MAX_WEIGHT,
  MIN_AGE,
  MIN_EDU,
  MIN_WEIGHT,
  PURE_VEG,
  SPECIAL_ABILITY,
  TO_HEIGHT_FEET,
  TO_HEIGHT_INCHES,
  MINIMUM_YEARLY_INCOME,
  FILTER_MIN_YEARLY_INCOME,
  FILTER_IS_NADI_DOSH,
  FILTER_IS_CHANDRA_DOSH,
  FILTER_IS_VYATIPATA_DOSH,
  FILTER_IS_RAHU_DOSH,
  FILTER_IS_PITRA_DOSH,
  FILTER_IS_GURU_CHANDAL_DOSH,
  FILTER_IS_KAAL_SARPA_DOSH,
  FILTER_IS_KEMADRUMA_DOSH,
  FILTER_IS_MANGLIK_DOSH,
  OPTIONAL_FILTERS,
  AGE,
  HEIGHT,
  WEIGHT,
  GENDER,
  FILTER_GENDER,
  NAME,
  FILTER_NAME_LIKE,
  FILTER_MAX_HEIGHT_INCHES,
} from "../Constants";

function getKundliDoshaFilterStr(criteriaStr) {
  let filterStr = "";
  const kDosha = criteriaStr.split(",");
  kDosha.forEach((kd) => {
    switch (kd.trim()) {
      case "Chandra_Dosha":
        filterStr += "&" + FILTER_IS_CHANDRA_DOSH + "=1";
        break;

      case "Guru_Chandal_Dosha":
        filterStr += "&" + FILTER_IS_GURU_CHANDAL_DOSH + "=1";
        break;

      case "Kaal_Sarpa_Dosha":
        filterStr += "&" + FILTER_IS_KAAL_SARPA_DOSH + "=1";
        break;

      case "Kemadruma_Dosha":
        filterStr += "&" + FILTER_IS_KEMADRUMA_DOSH + "=1";
        break;

      case "Mangal_Dosha":
        filterStr += "&" + FILTER_IS_MANGLIK_DOSH + "=1";
        break;

      case "Nadi_Dosha":
        filterStr += "&" + FILTER_IS_NADI_DOSH + "=1";
        break;

      case "Pitra_Dosha":
        filterStr += "&" + FILTER_IS_PITRA_DOSH + "=1";
        break;

      case "Rahu_Dosha":
        filterStr += "&" + FILTER_IS_RAHU_DOSH + "=1";
        break;

      case "Vyatipata_Dosha":
        filterStr += "&" + FILTER_IS_VYATIPATA_DOSH + "=1";
        break;
      default:
        console.log("UNHANDLED DOSHA: ", kd);
        break;
    }
  });
  return filterStr;
}
export function getFilterStr(searchCriteria) {
  let filterStr = "";
  const currentYear = new Date().getFullYear();
  Object.keys(searchCriteria).forEach((key) => {
    switch (key) {
      case DISTANCE_RANGE:
        filterStr +=
          "&" + FILTER_DISTANCE_RANGE + "=" + searchCriteria[DISTANCE_RANGE];
        break;
      case GOTRA:
        filterStr +=
          "&" +
          FILTER_GOTRA_IN +
          "=" +
          searchCriteria[GOTRA].replace(/\s+/g, ""); //remove blank spaces
        break;
      case MIN_AGE: //we will have to choose max birth year for min age
        const maxBirthYear = currentYear - parseInt(searchCriteria[MIN_AGE]);
        filterStr += "&" + FILTER_MAX_BIRTH_YEAR + "=" + maxBirthYear;
        break;
      case MAX_AGE: //we will have to choose min birth year for max age
        const minBirthYear = currentYear - parseInt(searchCriteria[MAX_AGE]);
        filterStr += "&" + FILTER_MIN_BIRTH_YEAR + "=" + minBirthYear;
        break;
      case MIN_EDU:
        filterStr +=
          "&" + FILTER_MIN_EDUCATION_LEVEL + "=" + searchCriteria[MIN_EDU];
        break;
      case MIN_WEIGHT:
        filterStr +=
          "&" +
          FILTER_MIN_WEIGHT +
          "=KG_" +
          String(searchCriteria[MIN_WEIGHT]).padStart(3, "0");
        break;
      case MAX_WEIGHT:
        filterStr +=
          "&" +
          FILTER_MAX_WEIGHT +
          "=KG_" +
          String(searchCriteria[MAX_WEIGHT]).padStart(3, "0");
        break;
      case FROM_HEIGHT_FEET:
        filterStr +=
          "&" + FILTER_MIN_HEIGHT_FEET + "=" + searchCriteria[FROM_HEIGHT_FEET];
        break;
      case FROM_HEIGHT_INCHES:
        filterStr +=
          "&" +
          FILTER_MIN_HEIGHT_INCHES +
          "=" +
          searchCriteria[FROM_HEIGHT_INCHES];
        break;
      case GENDER:
        filterStr += "&" + FILTER_GENDER + "=" + searchCriteria[GENDER];
        break;
      case KUNDLI_DOSHA:
        filterStr += getKundliDoshaFilterStr(searchCriteria[KUNDLI_DOSHA]);
        break;
      case MINIMUM_YEARLY_INCOME:
        filterStr +=
          "&" +
          FILTER_MIN_YEARLY_INCOME +
          "=" +
          searchCriteria[MINIMUM_YEARLY_INCOME];
        break;
      case TO_HEIGHT_FEET:
        filterStr +=
          "&" + FILTER_MAX_HEIGHT_FEET + "=" + searchCriteria[TO_HEIGHT_FEET];
        break;
      case TO_HEIGHT_INCHES:
        filterStr +=
          "&" +
          FILTER_MAX_HEIGHT_INCHES +
          "=" +
          searchCriteria[TO_HEIGHT_INCHES];
        break;
      case MARITAL_STATUS:
        filterStr +=
          "&" +
          FILTER_MARITAL_STATUS_IN +
          "=" +
          searchCriteria[MARITAL_STATUS].replace(/\s+/g, ""); //remove blank spaces
        break;
      case NAME:
        filterStr += "&" + FILTER_NAME_LIKE + "=" + searchCriteria[NAME];
        break;
      case PURE_VEG:
        const isPureVeg = searchCriteria[PURE_VEG] === "YES" ? "1" : "0";
        filterStr += "&" + FILTER_IS_PURE_VEG + "=" + isPureVeg;
        break;
      case SPECIAL_ABILITY:
        const isSpeciallyAbled =
          searchCriteria[SPECIAL_ABILITY] === "YES" ? "1" : "0";
        filterStr += "&" + FILTER_IS_SPECIALLY_ABLED + "=" + isSpeciallyAbled;
        break;

      default:
        console.log("UNHANDLED FILTER: ", key);
        break;
    }
  });
  return filterStr;
}

export function areSomeFiltersEmpty(
  optionalFilters,
  formData,
  CRITERIA_NAME,
  incompleteFilterMsg
) {
  const filtersUsedInCriteria = {};
  for (let i = 0; i < optionalFilters.length; i++) {
    const filterName = optionalFilters[i];
    switch (filterName) {
      case DISTANCE_RANGE:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Distance Range.";
          return true;
        }
        break;
      case MIN_EDU:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Minimum Education.";
          return true;
        }
        break;
      case AGE:
        if (formData[MIN_AGE] !== "" && formData[MAX_AGE] !== "") {
          filtersUsedInCriteria[MIN_AGE] = formData[MIN_AGE];
          filtersUsedInCriteria[MAX_AGE] = formData[MAX_AGE];
        } else {
          if (formData[MIN_AGE] === "") {
            incompleteFilterMsg["value"] = "Please choose Min Age.";
          } else if (formData[MAX_AGE] === "") {
            incompleteFilterMsg["value"] = "Please choose Max Age.";
          }
          return true;
        }
        break;
      case GENDER:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose " + filterName;
          return true;
        }
        break;
      case GOTRA:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Gotra.";
          return true;
        }
        break;
      case HEIGHT:
        if (
          formData[FROM_HEIGHT_FEET] !== "" &&
          formData[FROM_HEIGHT_INCHES] !== "" &&
          formData[TO_HEIGHT_FEET] !== "" &&
          formData[TO_HEIGHT_INCHES] !== ""
        ) {
          filtersUsedInCriteria[FROM_HEIGHT_FEET] = formData[FROM_HEIGHT_FEET];
          filtersUsedInCriteria[FROM_HEIGHT_INCHES] =
            formData[FROM_HEIGHT_INCHES];
          filtersUsedInCriteria[TO_HEIGHT_FEET] = formData[TO_HEIGHT_FEET];
          filtersUsedInCriteria[TO_HEIGHT_INCHES] = formData[TO_HEIGHT_INCHES];
        } else {
          if (formData[FROM_HEIGHT_FEET] === "") {
            incompleteFilterMsg["value"] = "Please choose Min Height Feet.";
          } else if (formData[FROM_HEIGHT_INCHES] === "") {
            incompleteFilterMsg["value"] = "Please choose Min Height Inches.";
          } else if (formData[TO_HEIGHT_FEET] === "") {
            incompleteFilterMsg["value"] = "Please choose Max Height Feet.";
          } else if (formData[TO_HEIGHT_INCHES] === "") {
            incompleteFilterMsg["value"] = "Please choose Max Height Inches.";
          }
          return true;
        }
        break;
      case WEIGHT:
        if (formData[MIN_WEIGHT] !== "" && formData[MAX_WEIGHT] !== "") {
          filtersUsedInCriteria[MIN_WEIGHT] = formData[MIN_WEIGHT];
          filtersUsedInCriteria[MAX_WEIGHT] = formData[MAX_WEIGHT];
        } else {
          if (formData[MIN_WEIGHT] === "") {
            incompleteFilterMsg["value"] = "Please choose Min Weight.";
          } else if (formData[MAX_WEIGHT] === "") {
            incompleteFilterMsg["value"] = "Please choose Max Weight.";
          }
          return true;
        }
        break;
      case MARITAL_STATUS:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Marital Status.";
          return true;
        }
        break;
      case NAME:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] =
            "Please type at least 2 letters for Name.";
          return true;
        }
        break;
      case KUNDLI_DOSHA:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Kundli Dosha.";
          return true;
        }
        break;
      case PURE_VEG:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose PureVeg option.";
          return true;
        }
        break;
      case SPECIAL_ABILITY:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] =
            "Please choose Special Ability option.";
          return true;
        }
        break;
      case MINIMUM_YEARLY_INCOME:
        if (formData[filterName] !== "") {
          filtersUsedInCriteria[filterName] = formData[filterName];
        } else {
          incompleteFilterMsg["value"] = "Please choose Yearly Income.";
          return true;
        }
        break;

      default:
        console.log("Unhandled filter type: ", filterName);
        break;
    } //switch
  } //for

  //save the chosen filters and their value in
  //localStorage and proceed to searchedPeople/Groom route
  //if data is not found then using the same criteriaName
  //from localStorage we will show the added filters.
  localStorage.setItem(CRITERIA_NAME, JSON.stringify(filtersUsedInCriteria));
  localStorage.setItem(OPTIONAL_FILTERS, JSON.stringify(optionalFilters));
  return false; //no filter is empty
}
