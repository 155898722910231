function NoPersonView() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // backgroundColor: "green",
        height: "40%",
        justifyContent: "center",
      }}
    >
      <label>No records...</label>
      <i style={{ color: "black" }} className="far fa-face-frown"></i>
    </div>
  );
}

export default NoPersonView;
